import { getMe } from './auth'

const apiURL = 'https://enszinhazambackend.herokuapp.com'
// const apiURL = 'http://localhost:9000'
const masterKey = '2w7I63elUBAWPBT8V37MY4xFZjFpMJnR'
const axios = require('axios')
const qs = require('query-string')

export const getProducts = () => (dispatch) => {
	axios
		.get(apiURL + '/products?access_token=' + masterKey)
		.then((response) => {
			dispatch({ type: 'GET_PRODUCTS', data: response.data })
		})
		.catch(function (error) {
			console.log(error)
			alert(error)
		})
}

export const getEvents = () => (dispatch) => {
	axios
		.get(apiURL + '/events?access_token=' + masterKey)
		.then((response) => {
			dispatch({ type: 'GET_EVENTS', data: response.data })
		})
		.catch(function (error) {
			console.log(error)
			alert(error)
		})
}

export const createOrder = (body) => async (dispatch, getState) => {
	console.log('create', body)
	await dispatch(getMe())

	axios
		.post(apiURL + '/orders', qs.stringify(body), {
			headers: {},
		})
		.then((response) => {
			if (response?.data?.errorCodes?.length > 0) {
				console.log(`error`, response.data.errorCodes)
				alert('valami hiba történt')
			} else {
				window.location.href = response.data.paymentUrl
			}
		})
		.catch(function (error) {
			console.log(error)
			alert('Valami hiba történt, próbáld újra! ')
			if (error.response) {
				if (error.response.status === 401) {
					dispatch({
						type: 'UPDATE_PASSWORD_FAILED',
						data: 'Hiba a belépésnél',
					})
				} else {
					dispatch({
						type: 'UPDATE_PASSWORD_FAILED',
						data: 'Rossz felhasználónév vagy jelszó',
					})
				}
			}
		})
}
