import React, { useState } from 'react'
import CustomButton from './CustomButton'

export const RollImages = ({ img, title }) => {
	const imgArray = img.split(';').map((i) => {
		return i.trim()
	})
	const [image, setImage] = useState(imgArray[1])
	const [isOpen, setIsOpen] = useState(false)

	return (
		<div className='flex flex-col lg:flex-row'>
			<div className='flex flex-col '>
				<div className={`${!isOpen && 'hidden'} lg:block`}>
					{imgArray.map((img) => (
						<div
							key={img}
							className={`w-90 p-1 rounded-xl lg:w-28 ${
								image !== img && 'lg:opacity-50'
							} lg:hover:opacity-100`}
							onClick={() => setImage(img)}>
							<img className='rounded-lg' src={img} alt={title} />
						</div>
					))}
				</div>
			</div>
			<div className={`${isOpen && 'hidden'} lg:block mx-auto`}>
				<img
					src={image}
					alt={title}
					className='p-1 object-contain rounded-xl lg:max-h-src80'
				/>
				<div className={`${isOpen && 'hidden'} lg:hidden text-center mb-4`}>
					<CustomButton
						onClick={() => {
							setIsOpen(true)
						}}>
						Több kép
					</CustomButton>
				</div>
			</div>
		</div>
	)
}
