import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePassword, checkFPasswordCode } from "../redux/actions/auth";
import Layout from "../components/Layout";
import TextField from "../components/TextField";
import CustomButton from "../components/CustomButton";
import { useHistory } from "react-router";

const NewPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");
  console.log(window.location)
  const code = window.location.pathname.replace('/newpass/', '');
  const codeError = useSelector((state) => state.auth.codeError);
  console.log(code)
  useEffect(() => {
    if (code) {
      dispatch(checkFPasswordCode(code));
    }
  }, []);

  useEffect(() => {
    if (codeError !== "") {
      alert("Kérj új kódot");
      history.push("/forgotten");
    }
  }, [codeError]);

  return (
    <Layout active="Profil">
      <div className="mx-24">
        <div className="mt-12 flex flex-col items-center w-5/12 mx-auto">
          <h2 className="text-2xl font-bold">Új jelszó</h2>
          <TextField
            type="password"
            label="Új jelszó:"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <CustomButton
            onClick={() => dispatch(updatePassword(password, code, history))}
          >
            Küldés
          </CustomButton>
        </div>
      </div>
    </Layout>
  );
};

export default NewPassword;
