export const addToCart = (item) => (dispatch, getState) => {
	dispatch({ type: 'ADD_TO_CART', data: item })
}

export const addToPlayCart = (item) => (dispatch, getState) => {
	dispatch({ type: 'ADD_TO_PLAY_CART', data: item })
}

export const rewriteCart = (item) => (dispatch) => {
	dispatch({ type: 'REWRITE_CART', data: item })
}

export const rewritePlayCart = (item) => (dispatch) => {
	dispatch({ type: 'REWRITE_PLAY_CART', data: item })
}
