var _ = require('lodash')

export const initialState = {
	products: [],
	events: [],
}

const api = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_PRODUCTS':
			return {
				...state,
				products: action.data,
			}
		case 'GET_EVENTS':
			return {
				...state,
				events: _.orderBy(action.data, function (o) {
					return new Date(o.date)
				}),
			}
		default:
			return state
	}
}

export default api
