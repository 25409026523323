import { combineReducers } from "redux";

import state from "./state";
import api from "./api";
import auth from "./auth";

const rootReducer = combineReducers({
  state,
  api,
  auth,
});

export default rootReducer;
