import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Privacy from '../screens/Privacy'
import PrivacyData from '../screens/PrivacyData'
import NewPassword from '../screens/NewPassword'
import { rewriteCart, rewritePlayCart } from '../redux/actions/state'
import { getCookie } from '../utils/cookies'
import { getMe } from '../redux/actions/auth'

import Highlight from '../screens/Highlight'
import Layout from '../components/Layout'

const Main = lazy(() => import('../screens/Main'))
const Products = lazy(() => import('../screens/Products'))
const Connect = lazy(() => import('../screens/Connect'))
const Events = lazy(() => import('../screens/Events'))
const Profile = lazy(() => import('../screens/Profil'))
const Login = lazy(() => import('../screens/Login'))
const Forgotten = lazy(() => import('../screens/Forgotten'))
const Cart = lazy(() => import('../screens/Cart'))
const CartData = lazy(() => import('../screens/CartData'))
const CartSum = lazy(() => import('../screens/CartSum'))
const CartThanks = lazy(() => import('../screens/CartThanks'))

const Routes = () => {
	const dispatch = useDispatch()
	const cart = useSelector((state) => state.state.cart)
	const playcart = useSelector((state) => state.state.playcart)
	const token = getCookie('token')

	useEffect(() => {
		if (token) {
			dispatch(getMe())
		}
		if (cart.length === 0) {
			const oldCart = JSON.parse(localStorage.getItem('cart'))
			if (oldCart) {
				dispatch(rewriteCart(oldCart))
			}
		} else {
			localStorage.removeItem('cart')
		}

		if (playcart.length === 0) {
			const oldPlayCart = JSON.parse(localStorage.getItem('playcart'))
			if (oldPlayCart) {
				dispatch(rewritePlayCart(oldPlayCart))
			}
		} else {
			localStorage.removeItem('playcart')
		}
	}, [])

	useEffect(() => {
		if (token) {
			dispatch(getMe())
		}
	}, [token])

	let routes = (
		<Switch>
			<Route exact path={`/`} component={Highlight} />
			<Route exact path={`/rólunk`} component={Main} />
			<Route path={`/termékek`} component={Products} />
			<Route path={`/csatlakozás`} component={Connect} />
			<Route path={`/eloadasok`} component={Events} />
			<Route path={`/előadások`} component={Events} />
			<Route path={`/profil`} component={Profile} />
			<Route path={`/login`} component={Login} />
			<Route path={`/forgotten`} component={Forgotten} />
			<Route path={`/newpass/:code`} component={NewPassword} />
			<Route exact path={`/kosár`} component={Cart} />
			<Route path={`/kosár/adatok`} component={CartData} />
			<Route path={`/kosár/összegzés`} component={CartSum} />
			<Route path={`/koszonjuk`} component={CartThanks} />

			<Route path={`/ászf`} component={Privacy} />
			<Route path={`/adatkezelési`} component={PrivacyData} />

			<Route path={`/profil`} component={Profile} />
		</Switch>
	)

	return (
		<Suspense
			fallback={
				<Layout active='Highlight'>
					<div className='mx-8 lg:mx-24'>
						<h1 className='mt-40 mx-auto text-center'>Loading...</h1>
					</div>
				</Layout>
			}>
			{routes}
		</Suspense>
	)
}

export default Routes
