import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import ReactPixel from 'react-facebook-pixel'
import { getAnalytics, logEvent } from 'firebase/analytics'

import CustomButton from './CustomButton'
import { addToCart } from '../redux/actions/state'
import { RollImages } from './RollImages'
import { forintFormat } from '../utils/utils'

const ProductCard = ({ product }) => {
	const analytics = getAnalytics()
	const dispatch = useDispatch()
	const history = useHistory()
	const [color, setColor] = useState('')
	const [count, setCount] = useState(1)
	const [size, setSize] = useState('')

	useEffect(() => {
		if (product.color.length === 1) setColor(product.color[0])
		if (product.size.length === 1) setSize(product.size[0])
	}, [])

	return (
		<div className='bg-grey lg:p-12 flex flex-col lg:flex-row text-white rounded-xl lg:justify-between'>
			<div className='lg:w-1/2'>
				<RollImages img={product.img} title={product.title} />
			</div>

			<div className='grid auto-rows-min grid-cols-1 lg:gap-y-8 lg:grid-cols-2 px-4 items-center lg:w-1/2 '>
				<div className='lg:col-span-2'>
					<div className='text-center text-2xl font-black pb-4'>
						{product.title}
					</div>
					<div className='text-center text-xl font-black py-4'>
						{forintFormat(product.price)}
					</div>
				</div>
				<div className='lg:col-span-2 text-center py-4 lg:py-0'>
					<div dangerouslySetInnerHTML={{ __html: product.description }} />
				</div>
				<div className='lg:hidden border-b-2 lg:border-b-0'></div>

				{product.size[0] !== 'ELFOGYOTT' ? (
					<>
						<div className='text-center lg:col-span-1 my-4'>
							<p>Válassz színt:</p>
							<div className='flex justify-center flex-row'>
								{product.color.map((item) => (
									<div
										key={item}
										className={`h-16 p-2  ${
											color === item && 'border-b-4 border-blue'
										}`}>
										<div
											onClick={() => setColor(item)}
											style={{ backgroundColor: item }}
											className={` h-12 w-12 rounded-full`}
										/>
									</div>
								))}
							</div>
						</div>
						<div className='flex justify-center flex-row lg:col-span-1 my-4'>
							<div className='mr-2'>
								<p>Válassz méretet:</p>
								<div className='flex justify-center p-2 flex-row'>
									{product.size.map((item) => (
										<button
											key={item}
											onClick={() => setSize(item)}
											className={`text-xl border p-2 rounded-full	uppercase mr-2 ${
												size === item ? 'text-blue' : 'text-white'
											}`}>
											{item}
										</button>
									))}
								</div>
							</div>
						</div>
						<div className='flex justify-center  flex-row  lg:col-span-1 my-4'>
							<div className='flex flex-row'>
								<div
									className='text-5xl font-black'
									onClick={() => {
										if (count > 1) {
											setCount(count - 1)
										}
									}}>
									-
								</div>
								<div className='text-5xl text-blue font-black mx-4'>
									{count}
								</div>
								<div
									className='text-5xl font-black'
									onClick={() => setCount(count + 1)}>
									+
								</div>
							</div>
						</div>
						<div className='mx-auto lg:col-span-1 my-4'>
							<CustomButton
								big
								type='submit'
								className='w-full'
								onClick={() => {
									if (color === '') {
										alert('Kérlek válassz egy színt')
									} else if (size === '') {
										alert('Kérlek válassz egy méretet')
									} else {
										const cartProduct = {
											color: color,
											count: count,
											size: size,
											product: product,
										}
										ReactPixel.track('AddToCart', cartProduct)
										logEvent(analytics, 'AddToCart', {
											product:
												product.title +
												' - ' +
												color +
												' - ' +
												size +
												' ' +
												count +
												' db',
										})
										dispatch(addToCart(cartProduct))
										history.push('/kosár')
									}
								}}>
								Kosárba
							</CustomButton>
						</div>
					</>
				) : (
					<div className='mx-auto lg:col-span-2 my-4'>
						<CustomButton big loading>
							Elfogyott
						</CustomButton>
					</div>
				)}
			</div>
		</div>
	)
}

export default ProductCard
