import React from "react";
import Layout from "../components/Layout";

const PrivacyData = () => {
  return (
    <Layout active="">
      <div className="mx-24">
        <h1 className="text-2xl mt-16 text-center text-xl font-bold mb-4">
          Adatkezelési tájékoztató
        </h1>
        <p>
          Adatkezelési tájékoztató a elektronikus kereskedelmi szolgáltatásának
          igénybevételéhez és kiegészítő jegyértékesítési szolgáltatásának
          igénybevételéhez.
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>1. Az adatkezelő adatai, elérhetőségei</strong>
        </p>
        <p>
          Név: Aurora Film és Színház Egyesület
          <br />
        </p>
        <p>
          Székhely: 1188. Budapest, Juharos sor 18. 2/12.
          <br />
        </p>
        <p>
          KSH-szám: 18904325-9001-529-01
          <br />
        </p>
        <p>
          Adószám: 18904325-1-43
          <br />
        </p>
        <p>
          Bankszámlaszám: Gránit Bank: 12100011-17687948
          <br />
        </p>
        <p>
          Telefon: 06 70 427-13-14
          <br />
        </p>
        <p>
          E-mail: info@enszinhazam.hu
          <br />
        </p>
        <p>
          Képviselő: Kreszner Zsófia <br />
        </p>
        <br />
        <p>
          <strong>2. Az adatkezelés elvei</strong>
          <br />
        </p>
        <p>
          Személyes adat kizárólag meghatározott célból, jog gyakorlása és
          kötelezettség teljesítése érdekében kezelhető. Az adatkezelésnek
          minden szakaszában meg kell felelnie az adatkezelés céljának, az
          adatok felvételének és kezelésének tisztességesnek és törvényesnek
          kell lennie.
          <br />
        </p>
        <p>
          Csak olyan személyes adat kezelhető, amely az adatkezelés céljának
          megvalósulásához elengedhetetlen, a cél elérésére alkalmas. A
          személyes adat csak a cél megvalósulásához szükséges mértékben és
          ideig kezelhető.
          <br />
          A személyes adat az adatkezelés során mindaddig megőrzi a minőségét,
          amíg kapcsolata az érintettel helyreállítható. Az érintettel akkor
          helyreállítható a kapcsolat, ha az adatkezelő rendelkezik azokkal a
          technikai feltételekkel, amelyek a helyreállításhoz szükségesek.
          <br />
        </p>
        <p>
          Az adatkezelés során biztosítani kell az adatok pontosságát,
          teljességét és – ha az adatkezelés céljára tekintettel szükséges –
          naprakészségét, valamint azt, hogy az érintettet csak az adatkezelés
          céljához szükséges ideig lehessen azonosítani.
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>3. Az adatkezelés célja</strong>
          <br />
        </p>
        <p>
          A Vállalkozás által végzett személyes adatokra vonatkozó adatkezelés a
          vele szerződéses kapcsolatba lépő személy (a továbbiakban Fogyasztó)
          önkéntes hozzájárulásán alapul.
          <br />
        </p>
        <p>
          A Fogyasztó által megadott személyes adatokat a Vállalkozás kizárólag
          a Vállalkozás és a Fogyasztó közötti szerződés létrehozása,
          tartalmának meghatározása, módosítása és teljesítése céljából
          használja fel.
          <br />
        </p>
        <p>
          Jelen Nyilatkozat a Fogyasztó nem nyilvánosságra szánt, hanem a
          Vállalkozás rendelkezésére bocsátott személyes adatainak védelmével
          kapcsolatos. Ha valaki önszántából nyilvánosságra hozza saját
          személyes adatait vagy azok egy részét, az ilyen információkra a
          Nyilatkozat hatálya nem terjed ki. Az átadott adatok kezelője a
          Vállalkozás.
          <br />
        </p>
        <p>
          A Fogyasztó által a Vállalkozás rendelkezésére bocsátott személyes
          adatokat ez irányú felhatalmazás hiányában a Vállalkozás nem adja
          tovább harmadik fél számára.
          <br />
        </p>
        <p>
          Amennyiben az arra feljogosított hatóságok a jogszabályokban előírt
          módon kérik fel személyes adatok átadására a Vállalkozást, az
          adatkezelő – törvényi kötelezettségének eleget téve – átadja a kért és
          rendelkezésre álló információkat.
          <br />
        </p>
        <p>
          A Vállalkozás biztosítja a Fogyasztók személyes adatainak biztonságát
          – mind a hálózati kommunikáció (tehát online adatkezelés) során, mind
          az adatok tárolása, őrzése (tehát offline adatkezelés) során. A
          személyes adatokhoz csak az illetékes munkaköröket betöltő személyek
          férhetnek hozzá.
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>4. Az adatkezelés jogalapja</strong>
          <br />
        </p>
        <p>
          Az adatkezelésre a www.enszinhazam.hu internetes oldalon található
          internetes tartalmak felhasználóinak önkéntes, megfelelő
          tájékoztatáson alapuló nyilatkozata alapján kerül sor, amely
          nyilatkozat tartalmazza a felhasználók kifejezett hozzájárulását
          ahhoz, hogy az oldal használata során közölt személyes adataik
          felhasználásra kerüljenek.
          <br />
        </p>
        <p>
          Az adatkezelés jogalapja:
          <br />
        </p>
        <p>
          az információs önrendelkezési jogról és az információszabadságról
          szóló 2011. évi CXII. törvény 5.§ (1) bekezdés a) pontja szerint az
          érintett önkéntes hozzájárulása,
          <br />
        </p>
        <p>
          az elektronikus kereskedelmi szolgáltatások, valamint az információs
          társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001.
          évi CVIII. törvény 13/A. §,
          <br />
        </p>
        <p>
          a kutatás és a közvetlen üzletszerzés célját szolgáló név- és
          lakcímadatok kezeléséről szóló 1995. évi CXIX. törvény 5. § (1)
          bekezdése: közvetlen üzletszerzés céljára történő adatkezelés.
          <br />
        </p>
        <p>
          AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016.
          április 27.) a természetes személyeknek a személyes adatok kezelése
          tekintetében történő védelméről és az ilyen adatok szabad áramlásáról,
          valamint a 95/46/EK rendelet hatályon kívül helyezéséről (általános
          adatvédelmi rendelet). <br />
          6. cikk (1) a.), b.) és c.) pontjai (önkéntes hozzájárulás, szerződés
          teljesítése és adatkezelőre vonatkozó jogi kötelezettség teljesítése).
          <br />
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>
            5. Az adatkezelésre és az adatfeldolgozásra jogosult személyek
          </strong>
          <br />
        </p>
        <p>
          Az adatok kezelésére és feldolgozására a szerződés létrehozása,
          tartalmának meghatározása, módosítása, teljesítése céljából kizárólag
          a Vállalkozás és a vele munkaviszonyban vagy megbízási viszonyban álló
          személyek, valamint a szállítási tevékenység elvégzéséhez szükséges
          mértékben a Fuvarozó és a vele megbízási-, illetve munkaviszonyban
          álló személyek jogosultak. Csomagjaink összeállítását és termékeink
          kiszállítását a GLS Futárszolgálat végzi.
          <br />
          <br />
        </p>
        <p>
          A jegyértékesítést az Aurora Film és Színház Egyesület (székhely:
          1188. Budapest, Juharos sor 18. 2/12., Adószám: 18904325-1-43) végzi
          kigészítő jellegű gazdasági tevékenységként. Jegyértékesítés esetén a
          számla kiállításához szükséges adatokat (Fogyasztó neve, Fogyasztó
          e-mail címe, Fogyasztó számlázási címe (számlázási név, utcanév,
          házszám, település, irányítószám), valamint a Fogyasztó telefonszámma)
          a Vállalkozás továbbítja az Aurora Film és Színház Egyesületnek.
          Kizárólag az ehhez szükséges adatokat a Switch to Two Bt. átadja
          számlázási céllal, melyet a számla létrejötte után az Aurora Film és
          Színház Egyesület a továbbiakban nem tárol.
          <br />
          <br />
        </p>
        <p>
          Adatok tárolásának helye: <br />
        </p>
        <p>
          A Vállalkozás és egy Egyesület a rendelkezésére bocsátott adatokat a
          következő rendszerekben tárolja: <br />
        </p>
        <br />
        <p>
          <a
            href="https://www.mongodb.com/legal/privacy-policy"
            alt="https://www.mongodb.com/legal/privacy-policy"
          >
            https://www.mongodb.com/legal/privacy-policy
          </a>
        </p>
        <p>
          <br />
        </p>
        <a
          href="https://www.heroku.com/policy/security"
          alt="https://www.heroku.com/policy/security"
        >
          https://www.heroku.com/policy/security
        </a>
        <p>
          <br />
        </p>
        <a
          href="https://firebase.google.com/support/privacy"
          alt="https://firebase.google.com/support/privacy"
        >
          https://firebase.google.com/support/privacy
        </a>
        <p>
          <br />
        </p>
        <a
          href="https://sendgrid.com/policies/security/"
          alt="https://sendgrid.com/policies/security/"
        >
          https://sendgrid.com/policies/security/
        </a>
        <p>
          <br />
        </p>
        <a
          href="https://simple.hu/adatkezelesi-tajekoztato/"
          alt="https://simple.hu/adatkezelesi-tajekoztato/"
        >
          https://simple.hu/adatkezelesi-tajekoztato/
        </a>
        <p>
          <br />
        </p>
        <a
          href="https://www.billingo.hu/adatkezelesi-tajekoztato"
          alt="https://www.billingo.hu/adatkezelesi-tajekoztato"
        >
          https://www.billingo.hu/adatkezelesi-tajekoztato
        </a>

        <p>
          <br />
        </p>
        <a
          href=" https://www.ezit.hu/adatvedelem/"
          alt=" https://www.ezit.hu/adatvedelem/"
        >
          https://www.ezit.hu/adatvedelem/
        </a>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>6. A kezelendő adatok köre</strong>
          <br />
        </p>
        <p>
          Az adatkezelők által kezelt személyes adatok köre
          <br />
        </p>
        <ul>
          <li>
            Fogyasztó neve,
            <br />
          </li>
          <li>
            felhasználónév,
            <br />
          </li>
          <li>
            saját e-mail cím,
            <br />
          </li>
          <li>
            jelszó,
            <br />
          </li>
          <li>
            számlázási cím (számlázási név, utcanév, házszám, település,
            irányítószám),
            <br />
          </li>
          <li>
            szállítási cím (szállítási név, utcanév, házszám, település,
            irányítószám),
            <br />
          </li>
          <li>
            telefonszám
            <br />
          </li>
        </ul>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>7. Az adatkezelés időtartama</strong>
          <br />
        </p>
        <p>
          Az adatkezelés időtartama a Fogyasztó és a Vállalkozás között fennálló
          ügyfélkapcsolat időtartama, vagy az az időtartam, amíg a Fogyasztó a
          hozzájárulását vissza nem vonja. Az adatkezelés a Fogyasztó általi
          regisztrációval veszi kezdetét. A Fogyasztó által önkéntesen magadott
          adatok haladéktalanul törlésre kerülnek a szerződés létrejöttének
          elmaradása, illetve a szerződés megszűnése esetén. Haladéktalanul
          törlésre kerülnek a Fogyasztó adatai abban az esetben is, amennyiben a
          Fogyasztó az adatkezelésre vonatkozó nyilatkozatát visszavonja. Az
          adatkezelés törlésével az adatkezelés véget ér. Fogyasztó az
          adatkezeléshez adott hozzájárulását bármikor visszavonhatja.
          <br />
        </p>
        <br />
        <p>
          A Vállalkozás a felvett adatokat törvény eltérő rendelkezésének
          hiányában a rá vonatkozó jogi kötelezettség teljesítése céljából, vagy
          a Vállalkozás, illetve harmadik személy jogos érdekének érvényesítése
          céljából – amennyiben ezen érdek érvényesítése a személyes adatok
          védelméhez fűződő jog korlátozásával arányban áll – további külön
          hozzájárulás nélkül, valamint a Fogyasztó hozzájárulásának
          visszavonását követően is kezelheti.
          <br />
        </p>
        <p>
          Fogyasztó az adatkezeléshez adott hozzájárulását bármikor
          visszavonhatja.
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>
            8. Az adatok továbbításának ténye és az adatok megismerésére
            jogosultak
          </strong>
          <br />
        </p>
        <p>
          Az adatokat a Vállalkozás a Fogyasztó hozzájárulásával a szerződés
          létrehozása, tartalmának meghatározása, módosítása, teljesítése
          céljából átadja a vele munkaviszonyban álló alkalmazottai, valamint a
          szállítási tevékenység elvégzéséhez szükséges mértékben a Fuvarozó és
          a vele megbízási-, illetve munkaviszonyban álló személyek részére,
          akik jogosultak az adatok megismerésére.
          <br />A Vállalkozás felhívja a figyelmet arra, hogy egyes, az 5. és a
          10. pontban feltüntetett vállalkozások tárolhatják az Ön adatait az
          Európai Gazdasági Térségen kívül található olyan országban is, ahol az
          adatok megfelelő védelme nem biztosított az általános adatvédelmi
          rendelet rendelkezéseinek megfelelően (pl.: USA). Ezekkel kapcsolatban
          bővebb tájékoztatást az 5. és 10. pontban hivatkozott vállalkozások
          adatvédelmi tájékoztatóiban talál.
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>9. Az érintettek jogai és jogorvoslati lehetőségei</strong>
          <br />
        </p>
        <p>
          A Fogyasztó kérelmezheti az adatkezelőnél tájékoztatását személyes
          adatai kezeléséről, személyes adatainak helyesbítését, valamint
          személyes adatainak – a kötelező adatkezelés kivételével – törlését
          vagy zárolását. A Fogyasztó tiltakozhat személyes adatának kezelése
          ellen, ha a személyes adatok kezelése vagy továbbítása kizárólag az
          adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez vagy az
          adatkezelő, adatátvevő vagy harmadik személy jogos érdekének
          érvényesítéséhez szükséges, kivéve kötelező adatkezelés esetén, ha a
          személyes adat felhasználása vagy továbbítása közvetlen üzletszerzés,
          közvélemény-kutatás vagy tudományos kutatás céljára történik, valamint
          törvényben meghatározott egyéb esetben.
        </p>
        <p>
          A Fogyasztó a jogainak megsértése esetén, valamint abban az esetben,
          ha a személyes adatainak kezelése elleni tiltakozása folytán az
          adatkezelő döntésével nem ért egyet, bírósághoz fordulhat. A bíróság
          az ügyben soron kívül jár el.
          <br />
        </p>
        <p>
          A per elbírálása törvényszéki hatáskörébe tartozik. A per – a
          Fogyasztó választása szerint – a Fogyasztó lakóhelye vagy tartózkodási
          helye szerinti törvényszék előtt is megindítható.
          <br />
        </p>
        <p>
          <strong>
            <br />
          </strong>
        </p>
        <p>
          <strong>
            10. Nyilatkozat személyes adatok kezeléséhez történő hozzájárulásról
          </strong>
          <br />
        </p>
        <p>
          A webáruházat üzemeltető a Vállalkozás mint adatkezelő kötelezettséget
          vállal, hogy bármely, a webáruház használata során tudomására jutott
          személyes adatot az információs önrendelkezési jogról és az
          információszabadságról szóló 2011. évi CXII. törvény és Kreszner B.
          Zsófia az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016.
          április 27.) a természetes személyeknek a személyes adatok kezelése
          tekintetében történő védelméről és az ilyen adatok szabad áramlásáról,
          valamint a 95/46/EK rendelet hatályon kívül helyezéséről rendelkezései
          szerint kezel. Amennyiben megrendelő bármely személyes adatát
          eljuttatja az adatkezelőhöz, azzal hozzájárulását adja ahhoz, hogy az
          adatkezelő a rendelések teljesítése, megrendelt csomagok kiszállítása
          érdekében adatait felhasználja, továbbá a webáruház üzemeltetetéséhez
          kapcsolódó adatbázist nyilvántartsa és kezelje kapcsolattartás, áru
          kiküldés, számlázási adatok megadása és csomagküldő tevékenység
          céljából az érintett kérésére történő törlésig.
          <br />
        </p>
        <br />
        <p>
          Adattárolás módja: <br />
        </p>
        <p>
          E-mailezésre:{" "}
          <a
            href="https://sendgrid.com/policies/security"
            alt="https://sendgrid.com/policies/security"
            target="balnk"
          >
            https://sendgrid.com/policies/security
          </a>
          ​<br />
        </p>
        <p>
          Az adattárolás helye:
          <a
            href="https://www.mongodb.com/legal/privacy-policy"
            alt=" https://www.mongodb.com/legal/privacy-policy"
            target="balnk"
          >
            {" "}
            https://www.mongodb.com/legal/privacy-policy
          </a>
          ​<br />
        </p>
        <p>
          Szerver amin átfut az adat :{" "}
          <a
            href="https://www.heroku.com/policy/security"
            alt="https://www.heroku.com/policy/security"
            target="balnk"
          >
            https://www.heroku.com/policy/security
          </a>
          ​<br />
        </p>
        <p>
          Google Firebase:{" "}
          <a
            href="https://firebase.google.com/support/privacy"
            alt="https://firebase.google.com/support/privacy"
            target="balnk"
          >
            https://firebase.google.com/support/privacy
          </a>
          ​<br />
        </p>
        <p>
          Google Analytics:{" "}
          <a
            href="https://policies.google.com/privacy"
            alt="https://policies.google.com/privacy"
            target="balnk"
          >
            https://policies.google.com/privacy
          </a>
          <br />
        </p>
        <p>
          Ezit.hu:{" "}
          <a
            href=" https://www.ezit.hu/adatvedelem/"
            alt=" https://www.ezit.hu/adatvedelem/"
          >
            https://www.ezit.hu/adatvedelem/
          </a>
          <br />
          <br />
          <br />
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyData;
