export const initialState = {
  errorMessage: null,
  token: "",
  user: {},
  codeError: "",
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.data.token,
        user: action.data.user,
        errorMessage: null,
      };
    case "UPDATE_PASSWORD_SUCCESS":
      return {
        ...state,
        user: action.data,
        errorMessage: null,
      };
    case "GET_ME":
      return {
        ...state,
        user: action.data,
        errorMessage: null,
      };
    case "LOGIN_FAILED":
      return {
        ...state,
        errorMessage: action.data,
      };
    case "LOGOUT":
      return {
        ...state,
        token: "",
        user: {},
        errorMessage: null,
      };
    case "PASSW_RESET_ERROR":
      return {
        ...state,
        codeError: "Sajnos már lejárt a kódod, kérj újat",
      };
    default:
      return state;
  }
};

export default auth;
