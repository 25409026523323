/* eslint react/forbid-prop-types: 0 */
import React from 'react'

const TextField = React.forwardRef(
	(
		{
			id,
			name,
			label,
			type,
			errors,
			groupClassName,
			fieldClassName,
			value,
			// defaultValue,
			onChange,
			disabled,
		},
		ref
	) => {
		const hasError = !!errors && !!errors[name]

		return (
			<div className='flex flex-col w-full my-1 z-1'>
				<div className={` flex flex-col w-full ${groupClassName || ''}`}>
					{label && (
						<label htmlFor={id || name} className={`w-full`}>
							{label}
						</label>
					)}
					<input
						name={name}
						id={id || name}
						type={type}
						ref={ref}
						disabled={disabled}
						onChange={onChange}
						value={value || ''}
						// defaultValue={defaultValue || ''}
						className={`border w-full bg-bg-black border-white border-solid rounded-lg p-2 focus:border-2 focus:border-blue focus:outline-none 
            ${disabled ? 'bg-disabled' : 'bg-white'}
            ${fieldClassName || ''}`}
					/>
				</div>
				{hasError ? (
					<div className='text-red'>{errors[name].message}</div>
				) : null}
			</div>
		)
	}
)

export default TextField
