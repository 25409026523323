export const initialState = {
	cart: [],
	playcart: [],
}

const updateCart = (stateCart, action) => {
	let updatedCart = [...stateCart]
	let itemIndex = -1

	if (updatedCart.length > 0) {
		itemIndex = updatedCart.findIndex(
			(c) =>
				c.product.id === action.data.product.id &&
				c.color === action.data.color &&
				c.size === action.data.size
		)
	}

	if (itemIndex > -1) {
		updatedCart[itemIndex].count += action.data.count
	} else {
		updatedCart = [...stateCart, action.data]
	}
	localStorage.setItem('cart', JSON.stringify(updatedCart))
	return updatedCart
}

const state = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_TO_CART': {
			const updatedCart = updateCart(state.cart, action)
			return {
				...state,
				cart: updatedCart,
			}
		}

		case 'REWRITE_CART': {
			return {
				...state,
				cart: action.data,
			}
		}

		case 'ADD_TO_PLAY_CART': {
			const updatedPlayCart = updateCart(state.playcart, action)
			return {
				...state,
				playcart: updatedPlayCart,
			}
		}

		case 'REWRITE_PLAY_CART':
			return {
				...state,
				playcart: action.data,
			}
		default:
			return state
	}
}

export default state
