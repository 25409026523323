import React from "react";
import Layout from "../components/Layout";

const Privacy = () => {
  return (
    <Layout active="">
      <div className="mx-24">
        <h1 className="text-2xl mt-16 text-center text-xl font-bold mb-4">
          ÁSZF
        </h1>
        <br />
        <h2 className="text-center">
          Impresszum: a Szolgáltató (Eladó, Vállalkozás) adatai
        </h2>
        <br />
        <div className="flex gap-12">
          <div>
            <h2>Tárhelyszolgáltató adatai</h2>
            <br />
            <p>Név: Websupport Magyarország Kft.</p>{" "}
            <p>Székhely: H-1132 – Budapest – Victor Hugo utca. 18-22.</p>{" "}
            <p>
              Elérhetőség: Telefon 0-24: +36 1 700 40 30 (telefonos menütérkép
              megtekintése)Ügyfélszolgálat: hétfőtől péntekig (munkanapokon)
              07:00-19:00Operátori felügyelet: 0-24 órában (szerver felügyelet)
            </p>
            <p>
              Tartalék telefonszám 0-24: +36 30 927 44 22**csak a központi
              számunk elérhetetlensége esetén
            </p>
            <p>
              Személyes ügyintézés: hétfőtől péntekig (munkanapokon)
              09:00-17:001132 Budapest, Victor Hugo u. 18-22. V. em 5021.
            </p>
            <p>Online ügyfélkapu: client.ezit.hu</p>
            <p>Általános ügyfélszolgálat: info@ezit.hu</p>
            <p>Ajánlatkérés: ertekesites@ezit.hu</p>
            <p>Domain ügyintézés: domain@ezit.hu</p>
            <p>
              Technikai kérdések, üzemeltetési osztály: rendszergazda@ezit.hu
            </p>
            <p>Számlázási kérdések, pénzügyi osztály: penzugy@ezit.hu</p>
            <p>Spam tevékenység, jogsértő tartalom bejelentés: abuse@ezit.hu</p>
            <p>Weboldal: https://www.ezit.hu/</p>
            <br />
            <h2>Fogalmak</h2>
            <p>Felek: Eladó és Vevő együttesen</p>
            <p>
              Fogyasztó: a szakmája, önálló foglalkozása vagy üzleti
              tevékenysége körén kívül eljáró természetes személy
            </p>
            <p>
              Fogyasztói szerződés: olyan szerződés, melynek egyik alanya
              fogyasztónak minősül
            </p>
            <p>
              Honlap: a jelen weboldal, amely a szerződés megkötésére szolgál
            </p>
            <p>
              Szerződés: Eladó és Vevő között a Honlap és elektronikus levelezés
              igénybevételével létrejövő adásvételi szerződés
            </p>
            <p>
              Távollévők közötti kommunikációt lehetővé tévő eszköz: olyan
              eszköz, amely alkalmas a felek távollétében – szerződés megkötése
              érdekében – szerződési nyilatkozat megtételére. Ilyen eszköz
              különösen a címzett vagy a címzés nélküli nyomtatvány, a
              szabványlevél, a sajtótermékben közzétett hirdetés
              megrendelőlappal, a katalógus, a telefon, a telefax és az
              internetes hozzáférést biztosító eszköz
            </p>
            <p>
              Távollévők között kötött szerződés: olyan fogyasztói szerződés,
              amelyet a szerződés szerinti termék vagy szolgáltatás nyújtására
              szervezett távértékesítési rendszer keretében a felek egyidejű
              fizikai jelenléte nélkül úgy kötnek meg, hogy a szerződés
              megkötése érdekében a szerződő felek kizárólag távollévők közötti
              kommunikációt lehetővé tévő eszközt alkalmaznak
            </p>
            <p>
              Termék: a Honlap kínálatában szereplő, a Honlapon értékesítésre
              szánt minden birtokba vehető forgalomképes ingó dolog, mely a
              Szerződés tárgyát képezi{" "}
            </p>
            <p>
              Vállalkozás: a szakmája, önálló foglalkozása vagy üzleti
              tevékenysége körében eljáró személy
            </p>
            <p>
              Vevő/Ön: a Honlapon keresztül vételi ajánlatot tevő szerződést
              kötő személy
            </p>
            <p>
              Jótállás: A fogyasztó és a vállalkozás között kötött szerződések
              esetén (a továbbiakban: fogyasztói szerződés) a Polgári
              Törvénykönyv szerinti,
            </p>
            <ol>
              <li>
                a szerződés teljesítéséért vállalt jótállás, amelyet a
                vállalkozás a szerződés megfelelő teljesítéséért a jogszabályi
                kötelezettségén túlmenően vagy annak hiányában önként vállal,
                valamint
                <br />
              </li>
              <li>a jogszabályon alapuló kötelező jótállás</li>
            </ol>
            <br />
            <p>
              A jelen Általános Szerződési Feltételek (a továbbiakban: ÁSZF)
              határozza meg az Eladó valamint az internetes jegyértékesítési
              rendszert használó természetes vagy jogi személy (a Vevő) között
              belépőjegyek (a továbbiakban: Jegy) vagy az Eladó weboldalán
              kínált egyéb termékek (a továbbiakban: Termék) megvásárlására
              vonatkozó szolgáltatás igénybevételének feltételeit, valamint a
              Felek között keletkező jogokat és kötelezettségeket.
            </p>
            <br />
            <p>
              Az Eladó internetes rendszert (honlapot) üzemeltet elsősorban
              különböző színházi, zenei és egyéb eseményekre szóló Jegyek és
              bérletek megvásárlásának elősegítésére, valamint Termékek (pl.
              ruha, ékszer) értékesítésére.
            </p>
            <br />
            <p>
              Az Eladó által értékesített Jegyeket a Vevők részére az Egyesület
              értékesíti. Az Eladó az Egyesület közvetítőjeként (ügynökeként)
              vesz részt. Az Eladó a Jeggyel látogatható rendezvény
              szervezésében és lebonyolításában nem vesz részt, tevékenysége és
              felelőssége kizárólag a Jegyek értékesítésében való közvetítői
              (ügynöki) részvételre korlátozódik. Mivel a rendezvényekre
              érvényes Jegyek értékesítésében az Eladó közvetítőként működik
              közre, így az ellenértékről szóló számlát a rendezvény szervezője
              (az Egyesület) bocsátja ki.
            </p>
            <br />
            <h2>Vonatkozó jogszabályok</h2>
            <br />
            <p>
              A Szerződésre a magyar jog előírásai az irányadóak, és különösen
              az alábbi jogszabályok vonatkoznak:
            </p>
            <br />
            <ul>
              <li>
                1997. évi CLV. törvény a fogyasztóvédelemről
                <br />
              </li>
              <li>
                2001. évi CVIII. törvény az elektronikus kereskedelmi
                szolgáltatások, valamint az információs társadalommal összefüggő
                szolgáltatások egyes kérdéseiről
              </li>
            </ul>
            <ul>
              <li>
                2013. évi V. törvény a Polgári Törvénykönyvről
                <br />
              </li>
              <li>
                151/2003. (IX.22.) kormányrendelet a tartós fogyasztási cikkekre
                vonatkozó kötelező jótállásról
                <br />
              </li>
              <li>
                45/2014. (II.26.) kormányrendelet a fogyasztó és a vállalkozás
                közötti szerződések részletes szabályairól <br />
              </li>
              <li>
                19/2014. (IV.29.) NGM rendelet a fogyasztó és vállalkozás
                közötti szerződés keretében eladott dolgokra vonatkozó
                szavatossági és jótállási igények intézésének eljárási
                szabályairól
                <br />
              </li>
              <li>
                1999. évi LXXVI. törvény a szerzői jogról
                <br />
              </li>
              <li>
                2011. évi CXII. törvény az információs önrendelkezési jogról és
                az információszabadságról. <br />
              </li>
              <li>
                AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2018/302 RENDELETE (2018.
                febr/uár 28.) a belső piacon belül a vevő állampolgársága,
                lakóhelye vagy letelepedési helye alapján történő indokolatlan
                területi alapú tartalomkorlátozással és a megkülönböztetés egyéb
                formáival szembeni fellépésről, valamint a 2006/2004/EK és az
                (EU) 2017/2394 rendelet, továbbá a 2009/22/EK irányelv
                módosításáról
                <br />
              </li>
              <li>
                AZ EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016.
                április 27.) a természetes személyeknek a személyes adatok
                kezelése tekintetében történő védelméről és az ilyen adatok
                szabad áramlásáról, valamint a 95/46/EK rendelet hatályon kívül
                helyezéséről (általános adatvédelmi rendelet)
              </li>
            </ul>
            <br />
            <h2>Adattovábbítási nyilatkozat</h2>
            <br />
            <p>
              Tudomásul veszem, hogy a(z) Aurora Film és Színház Egyesület
              (1188. Budapest, Juharos sor 18 2/12) adatkezelő által a(z)
              <a href="www.enszinhazam.hu"> www.enszinhazam.hu </a>
              felhasználói adatbázisában tárolt alábbi személyes adataim
              átadásra kerülnek az OTP Mobil Kft., mint adatfeldolgozó részére.
              Az adatkezelő által továbbított adatok köre az alábbi:
              <br />
            </p>
            <ul>
              <li>fogyasztó neve</li>
              <li>saját e-mail cím,</li>
              <li>
                számlázási cím (számlázási név, utcanév, házszám, település,
                irányítószám)
              </li>
              <li>telefonszám</li>
            </ul>
            <p>
              <br />
              Az adatfeldolgozó által végzett adatfeldolgozási tevékenység
              jellege és célja a SimplePay Adatkezelési tájékoztatóban, az
              alábbi linken tekinthető meg:
              <a
                href="http://simplepay.hu/vasarlo-aff"
                target="_blank"
                rel="noreferrer"
              >
                http://simplepay.hu/vasarlo-aff
              </a>
            </p>
            <p>
              A Vállalkozás és az Egyesület részére nyújtott adatok kezelésére
              az alábbi Adatkezelési Tájékoztató vonatkozik:{" "}
              <a href="http://www.enszinhazam.hu/adatkezel%C3%A9si">
                http://www.enszinhazam.hu/adatkezel%C3%A9si
              </a>
            </p>
            <br />
            <h2>Az ÁSZF hatálya, elfogadása</h2>
            <br />
            <p>
              A közöttünk létrejövő szerződés tartalmát – a vonatkozó kötelező
              érvényű jogszabályok rendelkezései mellett – a jelen Általános
              Szerződési Feltételek (a továbbiakban: ÁSZF) határozzák meg. Ennek
              megfelelően tartalmazza a jelen ÁSZF az Önt és bennünket illető
              jogokat és kötelezettségeket, a szerződés létrejöttének
              feltételeit, a teljesítési határidőket, a szállítási és fizetési
              feltételeket, a felelősségi szabályokat, valamint az elállási jog
              gyakorlásának feltételeit.
            </p>
            <br />
            <p>
              A Honlap használatához szükséges azon technikai tájékoztatást,
              melyet jelen ÁSZF nem tartalmaz, a Honlapon elérhető egyéb
              tájékoztatások nyújtják.
            </p>
            <br />
            <p>
              Ön a megrendelése véglegesítése előtt köteles megismerni a jelen
              ÁSZF rendelkezéseit.{" "}
            </p>
            <br />
            <h2>A szerződés nyelve, a szerződés formája</h2>
            <br />
            <p>
              A jelen ÁSZF hatálya alá tartozó szerződések nyelve a magyar
              nyelv.
            </p>
            <br />
            <p>
              A létrejött szerződés írásbeli szerződésnek minősül, a szerződés
              létrejöttét a Vevő által megadott, elektronikusan elmentett
              vásárlási adatok igazolják, amelyeket az Eladó a számvitelre és
              adózásra vonatkozó jogszabályokban meghatározott határideig őriz.
            </p>
            <br />
            <h2>Árak</h2>
            <br />
            <p>
              Az árak forintban értendők, tartalmazzák a 27%-os ÁFÁ-t. Nem
              zárható ki annak a lehetősége, hogy üzletpolitikai okból az Eladó
              az árakat módosítsa. Az árak módosítása nem terjed ki a már
              megkötött szerződésekre. Amennyiben Eladó az árat hibásan tüntette
              fel, és megrendelés érkezett a termékre, de szerződést még nem
              kötöttek a felek, úgy az ÁSZF „Eljárás hibás ár” pontja alapján
              jár el az Eladó.
            </p>
            <br />
            <h2>Eljárás hibás ár esetén</h2>
            <br />
            <p>Nyilvánvalóan hibásan feltüntetett árnak minősül:</p>
            <br />
            <ul>
              <li>
                0 Ft-os ár,
                <br />
              </li>
              <li>
                kedvezménnyel csökkentett, de a kedvezményt tévesen feltüntető
                ár (pl.: 1000 Ft-os termék esetén a 20 %-os kedvezmény
                feltüntetése mellett 500 Ft-ért kínált termék).
              </li>
            </ul>
            <br />
            <p>
              Hibás ár feltüntetése esetén Eladó felajánlja a termék valós áron
              történő megvásárlásának lehetőségét, mely információ birtokában a
              Vásárló eldöntheti, hogy megrendeli valós áron a terméket vagy
              minden hátrányos jogkövetkezmény nélkül lemondja a megrendelést.
            </p>
            <br />
            <h2>Panaszügyintézés és jogérvényesítési lehetőségek</h2>
            <br />
            <p>
              A fogyasztó a termékkel vagy az Eladó tevékenységével kapcsolatos
              fogyasztói kifogásait az alábbi elérhetőségeken terjesztheti elő:
            </p>
            <br />
            <ul>
              <li>
                Telefon: +36 70 4271314
                <br />
              </li>
              <li>
                Internet cím: https://enszinhazam.hu
                <br />
              </li>
              <li>E-mail: info@enszinhazam.hu</li>
            </ul>
            <br />
            <p>
              A fogyasztó szóban vagy írásban közölheti a vállalkozással a
              panaszát, amely a vállalkozásnak, illetve a vállalkozás érdekében
              vagy javára eljáró személynek az áru fogyasztók részére történő
              forgalmazásával, illetve értékesítésével közvetlen kapcsolatban
              álló magatartására, tevékenységére vagy mulasztására vonatkozik.
            </p>
            <br />
            <p>
              A szóbeli panaszt a vállalkozás köteles azonnal megvizsgálni, és
              szükség szerint orvosolni. Ha a fogyasztó a panasz kezelésével nem
              ért egyet, vagy a panasz azonnali kivizsgálása nem lehetséges, a
              vállalkozás a panaszról és az azzal kapcsolatos álláspontjáról
              haladéktalanul köteles jegyzőkönyvet felvenni, és annak egy
              másolati példányát személyesen közölt szóbeli panasz esetén
              helyben a fogyasztónak átadni. Telefonon vagy egyéb elektronikus
              hírközlési szolgáltatás felhasználásával közölt szóbeli panasz
              esetén a fogyasztónak legkésőbb 30 napon belül - az írásbeli
              panaszra adott válaszra vonatkozó előírásoknak megfelelően - az
              érdemi válasszal egyidejűleg megküldeni. Egyebekben pedig az
              írásbeli panaszra vonatkozóan az alábbiak szerint köteles eljárni.
              Az írásbeli panaszt a vállalkozás - ha az Európai Unió közvetlenül
              alkalmazandó jogi aktusa eltérően nem rendelkezik - a beérkezését
              követően harminc napon belül köteles írásban érdemben
              megválaszolni és intézkedni annak közlése iránt. Ennél rövidebb
              határidőt jogszabály, hosszabb határidőt törvény állapíthat meg. A
              panaszt elutasító álláspontját a vállalkozás indokolni köteles. A
              telefonon vagy elektronikus hírközlési szolgáltatás
              felhasználásával közölt szóbeli panaszt a vállalkozás köteles
              egyedi azonosítószámmal ellátni.
            </p>
            <br />
            <p>
              A panaszról felvett jegyzőkönyvnek tartalmaznia kell az
              alábbiakat:
            </p>
            <br />
            <ol>
              <li>
                a fogyasztó neve, lakcíme,
                <br />
              </li>
              <li>
                a panasz előterjesztésének helye, ideje, módja,
                <br />
              </li>
              <li>
                a fogyasztó panaszának részletes leírása, a fogyasztó által
                bemutatott iratok, dokumentumok és egyéb bizonyítékok jegyzéke,
                <br />
              </li>
              <li>
                a vállalkozás nyilatkozata a fogyasztó panaszával kapcsolatos
                álláspontjáról, amennyiben a panasz azonnali kivizsgálása
                lehetséges,
                <br />
              </li>
              <li>
                a jegyzőkönyvet felvevő személy és - telefonon vagy egyéb
                elektronikus hírközlési szolgáltatás felhasználásával közölt
                szóbeli panasz kivételével - a fogyasztó aláírása,
                <br />
              </li>
              <li>
                a jegyzőkönyv felvételének helye, ideje,
                <br />
              </li>
              <li>
                telefonon vagy egyéb elektronikus hírközlési szolgáltatás
                felhasználásával közölt szóbeli panasz esetén a panasz egyedi
                azonosítószáma.
              </li>
            </ol>
            <br />
            <p>
              A vállalkozás a panaszról felvett jegyzőkönyvet és a válasz
              másolati példányát három évig köteles megőrizni, és azt az
              ellenőrző hatóságoknak kérésükre bemutatni.
            </p>
            <br />
            <p>
              A panasz elutasítása esetén a vállalkozás köteles a fogyasztót
              írásban tájékoztatni arról, hogy panaszával - annak jellege
              szerint - mely hatóság vagy békéltető testület eljárását
              kezdeményezheti. A tájékoztatásnak tartalmaznia kell továbbá az
              illetékes hatóság, illetve a fogyasztó lakóhelye vagy tartózkodási
              helye szerinti békéltető testület székhelyét, telefonos és
              internetes elérhetőségét, valamint levelezési címét. A
              tájékoztatásnak arra is ki kell terjednie, hogy a vállalkozás a
              fogyasztói jogvita rendezése érdekében igénybe veszi-e a békéltető
              testületi eljárást. Amennyiben az Eladó és a fogyasztó között
              esetlegesen fennálló fogyasztói jogvita a tárgyalások során nem
              rendeződik, az alábbi jogérvényesítési lehetőségek állnak nyitva a
              fogyasztó számára:
            </p>
            <br />
            <h2>FOGYASZTÓVÉDELMI ELJÁRÁS</h2>
            <br />
            <p>
              Panasztétel a fogyasztóvédelmi hatóságoknál. Amennyiben a
              fogyasztó fogyasztói jogainak megsértését észleli, jogosult
              panasszal fordulni a lakóhelye szerint illetékes fogyasztóvédelmi
              hatósághoz. A panasz elbírálását követően a hatóság dönt a
              fogyasztóvédelmi eljárás lefolytatásáról. A fogyasztóvédelmi
              elsőfokú hatósági feladatokat a fogyasztó lakóhelye szerint
              illetékes fővárosi és megyei kormányhivatalok látják el, ezek
              listája itt található: http://www.kormanyhivatal.hu/
            </p>
            <br />
            <h2>BÍRÓSÁGI ELJÁRÁS</h2>
            <br />
            <p>
              Ügyfél jogosult a fogyasztói jogvitából származó követelésének
              bíróság előtti érvényesítésére polgári eljárás keretében a Polgári
              Törvénykönyvről szóló 2013. évi V. törvény, valamint a Polgári
              Perrendtartásról szóló 2016. évi CXXX. törvény rendelkezései
              szerint.
            </p>
            <br />
            <h2>BÉKÉLTETŐ TESTÜLETI ELJÁRÁS</h2>
            <br />
            <p>
              Tájékoztatjuk, hogy Ön velünk szemben fogyasztói panasszal élhet.
              Amennyiben az Ön fogyasztói panaszát elutasítjuk, úgy Ön jogosult
              az Ön lakóhelye vagy tartózkodási helye szerint illetékes
              Békéltető Testülethez is fordulni: a békéltető testület eljárása
              megindításának feltétele, hogy a fogyasztó az érintett
              vállalkozással közvetlenül megkísérelje a vitás ügy rendezését. Az
              eljárásra - a fogyasztó erre irányuló kérelme alapján - az
              illetékes testület helyett a fogyasztó kérelmében megjelölt
              békéltető testület illetékes.
            </p>
            <br />
            <p>
              A vállalkozást a békéltető testületi eljárásban együttműködési
              kötelezettség terheli.
            </p>
            <br />
            <p>
              Ennek keretében fennáll a vállalkozásoknak a békéltető testület
              felhívására történő válaszirat megküldési kötelezettsége, továbbá
              kötelezettségként kerül rögzítésre a békéltető testület előtti
              megjelenési kötelezettség („meghallgatáson egyezség
              létrehozatalára feljogosított személy részvételének biztosítása”).
            </p>
            <br />
            <p>
              Amennyiben a vállalkozás székhelye vagy telephelye nem a
              területileg illetékes békéltető testületet működtető kamara
              szerinti megyébe van bejegyezve, a vállalkozás együttműködési
              kötelezettsége a fogyasztó igényének megfelelő írásbeli
              egyezségkötés lehetőségének felajánlására terjed ki.
            </p>
            <br />
            <p>
              A fenti együttműködési kötelezettség megszegése esetén a
              fogyasztóvédelmi hatóság rendelkezik hatáskörrel, amely alapján a
              jogszabályváltozás következtében a vállalkozások jogsértő
              magatartása esetén kötelező bírságkiszabás alkalmazandó, bírságtól
              való eltekintésre nincs lehetőség. A fogyasztóvédelemről szóló
              törvény mellett módosításra került a kis- és középvállalkozásokról
              szóló törvény vonatkozó rendelkezése is, így a kis- és
              középvállalkozások esetén sem mellőzhető majd a bírság kiszabása.
            </p>
            <br />
            <p>
              A bírság mértéke kis- és középvállalkozások esetén 15 ezer
              forinttól 500 ezer forintig terjedhet, míg a számviteli törvény
              hatálya alá tartozó, 100 millió forintot meghaladó éves nettó
              árbevétellel rendelkező, nem kis- és középvállalkozás esetén 15
              ezer forinttól, a vállalkozás éves nettó árbevételének 5%-áig, de
              legfeljebb 500 millió forintig terjedhet. A kötelező bírság
              bevezetésével a jogalkotó a békéltető testületekkel való
              együttműködés nyomatékosítását, illetve a vállalkozásoknak a
              békéltető testületi eljárásban való aktív részvételének
              biztosítását célozza.
            </p>
            <br />
            <p>
              A békéltető testület hatáskörébe tartozik a fogyasztói jogvita
              bírósági eljáráson kívüli rendezése. A békéltető testület
              feladata, hogy megkísérelje a fogyasztói jogvita rendezése
              céljából egyezség létrehozását a felek között, ennek
              eredménytelensége esetén az ügyben döntést hoz a fogyasztói jogok
              egyszerű, gyors, hatékony és költségkímélő érvényesítésének
              biztosítása érdekében. A békéltető testület a fogyasztó vagy a
              vállalkozás kérésére tanácsot ad a fogyasztót megillető jogokkal
              és a fogyasztót terhelő kötelezettségekkel kapcsolatban.
            </p>
            <br />
            <p>
              A békéltető testület eljárása a fogyasztó kérelmére indul. A
              kérelmet a békéltető testület elnökéhez kell írásban benyújtani:
              az írásbeliség követelményének levél, távirat, távgépíró vagy
              telefax útján, továbbá bármely egyéb olyan eszközzel is eleget
              lehet tenni, amely a címzett számára lehetővé teszi a neki címzett
              adatoknak az adat céljának megfelelő ideig történő tartós
              tárolását, és a tárolt adatok változatlan formában és tartalommal
              történő megjelenítését.A kérelemnek tartalmaznia kell
            </p>
            <br />
            <ol>
              <li>
                a fogyasztó nevét, lakóhelyét vagy tartózkodási helyét,
                <br />
              </li>
              <li>
                a fogyasztói jogvitával érintett vállalkozás nevét, székhelyét
                vagy érintett telephelyét,
                <br />
              </li>
              <li>
                ha a fogyasztó az illetékességet az illetékes békéltető testület
                helyett kérelmezett testület megjelölését,
                <br />
              </li>
              <li>
                a fogyasztó álláspontjának rövid leírását, az azt alátámasztó
                tényeket és azok bizonyítékait,
                <br />
              </li>
              <li>
                a fogyasztó nyilatkozatát arról, hogy a fogyasztó az érintett
                vállalkozással közvetlenül megkísérelte a vitás ügy rendezését
                <br />
                <br />
              </li>
              <li>
                a fogyasztó nyilatkozatát arra nézve, hogy az ügyben más
                békéltető testület eljárását nem kezdeményezte, közvetítői
                eljárás nem indult, keresetlevél beadására, illetve fizetési
                meghagyás kibocsátása iránti kérelemelőterjesztésére nem került
                sor,
                <br />
              </li>
              <li>
                a testület döntésére irányuló indítványt,
                <br />
              </li>
              <li>a fogyasztó aláírását.</li>
            </ol>
            <br />
            <p>
              A kérelemhez csatolni kell azt az okiratot, illetve annak
              másolatát (kivonatát), amelynek tartalmára a fogyasztó
              bizonyítékként hivatkozik, így különösen a vállalkozás írásbeli
              nyilatkozatát a panasz elutasításáról, ennek hiányában a fogyasztó
              rendelkezésére álló egyéb írásos bizonyítékot az előírt egyeztetés
              megkísérléséről.
            </p>
            <br />
            <p>
              Ha a fogyasztó meghatalmazott útján jár el, a kérelemhez csatolni
              kell a meghatalmazást.
            </p>
            <br />
            <p>
              A Békéltető Testületekről bővebb információ itt érhető el:
              http://www.bekeltetes.hu
            </p>
            <br />
            <p>
              A területileg illetékes Békéltető Testületekről bővebb információ
              itt érhető el:
            </p>
            <br />
            <p>https://bekeltetes.hu/index.php?id=testuletek</p>
            <br />
            <h3>
              AZ EGYES TERÜLETILEG ILLETÉKES BÉKÉLTETŐ TESTÜLETEK ELÉRHETŐSÉGEI:
            </h3>
            <table>
              <tbody>
                <tr>
                  <td>
                    <div>
                      Baranya Megyei Békéltető Testület
                      <br />
                      Cím: 7625 Pécs, Majorossy I. u. 36.
                      <br />
                      Telefonszám: 06-72-507-154
                      <br />
                      Fax: 06-72-507-152
                      <br />
                      E-mail: abeck@pbkik.hu; mbonyar@pbkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Bács-Kiskun Megyei Békéltető Testület
                      <br />
                      Cím: 6000 Kecskemét, Árpád krt. 4.
                      <br />
                      Telefonszám: 06-76-501-500; 06-76-501-525, 06-76-501-523
                      <br />
                      Fax: 06-76-501-538
                      <br />
                      E-mail: bekeltetes@bacsbekeltetes.hu;
                      mariann.matyus@bkmkik.hu
                      <br />
                      Honlap: www.bacsbekeltetes.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Békés Megyei Békéltető Testület
                      <br />
                      Cím: 5600 Békéscsaba, Penza ltp. 5.
                      <br />
                      Telefonszám: 06-66-324-976
                      <br />
                      Fax: 06-66-324-976
                      <br />
                      E-mail: eva.toth@bmkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Borsod-Abaúj-Zemplén Megyei Békéltető Testület
                      <br />
                      Cím: 3525 Miskolc, Szentpáli u. 1.
                      <br />
                      Telefonszám:06-46-501-091;06-46-501-870
                      <br />
                      Fax: 06-46-501-099
                      <br />
                      E-mail: bekeltetes@bokik.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Budapesti Békéltető Testület
                      <br />
                      Cím: 1016 Budapest, Krisztina krt. 99. 99. I. em. 111.
                      <br />
                      Telefonszám: 06-1-488-2131
                      <br />
                      E-mail: bekelteto.testulet@bkik.hu
                      <br />
                      Honlap: bekeltet.bkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Csongrád Megyei Békéltető Testület
                      <br />
                      Cím: 6721 Szeged, Párizsi krt. 8-12.
                      <br />
                      Telefonszám: 06-62-554-250/118
                      <br />
                      Fax: 06-62-426-149
                      <br />
                      E-mail: bekelteto.testulet@csmkik.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Fejér Megyei Békéltető Testület
                      <br />
                      Cím: 8000 Székesfehérvár, Hosszúsétatér 4-6.
                      <br />
                      Telefonszám:06-22-510-310
                      <br />
                      Fax: 06-22-510-312
                      <br />
                      E-mail: fmkik@fmkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Győr-Moson-Sopron Megyei Békéltető Testület
                      <br />
                      Cím: 9021 Győr, Szent István út 10/a.
                      <br />
                      Telefonszám: 06-96-520-217
                      <br />
                      Fax: 06-96-520-218
                      <br />
                      E-mail: bekeltetotestulet@gymskik.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Hajdú-Bihar Megyei Békéltető Testület
                      <br />
                      Cím: 4025 Debr/ecen, Vörösmarty u. 13-15.
                      <br />
                      Telefonszám: 06-52-500-710
                      <br />
                      Fax: 06-52-500-720
                      <br />
                      E-mail: korosi.vanda@hbkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Heves Megyei Békéltető Testület
                      <br />
                      Cím: 3300 Eger, Faiskola út 15.
                      <br />
                      Telefonszám: 06-36-429-612
                      <br />
                      Fax: 06-36-323-615
                      <br />
                      E-mail: hkik@hkik.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Jász-Nagykun-Szolnok Megyei Békéltető Testület
                      <br />
                      Cím: 5000 Szolnok, Verseghy park 8. III. emelet 305-306.
                      <br />
                      Telefonszám: 06-56-510-621, 06-20-373-2570
                      <br />
                      Fax: 06-56-510-628
                      <br />
                      E-mail: bekeltetotestulet@jnszmkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Komárom-Esztergom Megyei Békéltető Testület
                      <br />
                      Cím: 2800 Tatabánya, Fő tér 36.
                      <br />
                      Telefonszám: 06-34-513-027
                      <br />
                      Fax: 06-34-316-259
                      <br />
                      E-mail: szilvi@kemkik.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Nógrád Megyei Békéltető Testület
                      <br />
                      Cím: 3100 Salgótarján, Alkotmány út 9/A.
                      <br />
                      Telefonszám: 06-32-520-860
                      <br />
                      Fax: 06-32-520-862
                      <br />
                      E-mail: nkik@nkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Pest Megyei Békéltető Testület
                      <br />
                      Cím: 1055 Budapest, Balassi Bálint u. 25. IV/2.
                      <br />
                      Levelezési cím: 1364 Budapest, Pf.: 81
                      <br />
                      Telefonszám: 06-1-792-7881
                      <br />
                      E-mail: pmbekelteto@pmkik.hu
                      <br />
                      Honlap: http://panaszrendezes.hu/
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Somogy Megyei Békéltető Testület
                      <br />
                      Cím: 7400 Kaposvár, Anna u.6.
                      <br />
                      Telefonszám: 06-82-501-026
                      <br />
                      Fax: 06-82-501-046
                      <br />
                      E-mail: skik@skik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Szabolcs-Szatmár-Bereg Megyei Békéltető Testület
                      <br />
                      Cím: 4400 Nyíregyháza, Széchenyi u. 2.
                      <br />
                      Telefonszám: 06-42-311-544
                      <br />
                      Fax: 06-42-311-750
                      <br />
                      E-mail: bekelteto@szabkam.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Tolna Megyei Békéltető Testület
                      <br />
                      Cím: 7100 Szekszárd, Arany J. u. 23-25. III. emelet
                      <br />
                      Telefonszám: 06-74-411-661
                      <br />
                      Fax: 06-74-411-456
                      <br />
                      E-mail: kamara@tmkik.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Vas Megyei Békéltető Testület
                      <br />
                      Cím: 9700 Szombathely, Honvéd tér 2.
                      <br />
                      Telefonszám: 06-94-312-356
                      <br />
                      Fax: 06-94-316-936
                      <br />
                      E-mail: vmkik@vmkik.hu
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      Veszprém Megyei Békéltető Testület
                      <br />
                      Cím: 8200 Veszprém, Radnóti tér 1. földszint 115-116.
                      <br />
                      Telefonszám: +36-88-814-121 <br />
                      Fax: 06-88-412-150
                      <br />
                      E-mail: info@bekeltetesveszprem.hu
                      <br />
                    </div>
                  </td>
                  <td>
                    <div>
                      Zala Megyei Békéltető Testület
                      <br />
                      Cím: 8900 Zalaegerszeg, Petőfi u. 24.
                      <br />
                      Telefonszám: 06-92-550-513
                      <br />
                      Fax: 06-92-550-525
                      <br />
                      E-mail: zmbekelteto@zmkik.hu
                      <br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <h2>Online vitarendezési platform</h2>
            <br />
            <p>
              Az Európai Bizottság létrehozott egy honlapot, amelybe a
              fogyasztók beregisztrálhatnak, így ezen keresztül lehetőségük
              nyílik arra, hogy online vásárláshoz kapcsolódó jogvitáikat ezen
              keresztül rendezzék egy kérelem kitöltésével, elkerülve a bírósági
              eljárást. Így a fogyasztók tudják érvényesíteni jogaikat anélkül,
              hogy például a távolság meggátolná őket ebben.
            </p>
            <br />
            <p>
              Ha Ön panaszt kíván tenni egy, az interneten vásárolt termékkel
              vagy szolgáltatással kapcsolatban, és nem akar feltétlenül
              bírósághoz fordulni, igénybe veheti az online vitarendezés
              eszközét.
            </p>
            <br />
            <p>
              A portálon Ön és a kereskedő, akivel szemben panasszal élt,
              közösen kiválaszthatják a panasz kezelésével megbízni kívánt
              vitarendezési testületet.
            </p>
            <br />
            <p>
              Az online vitarendezési platform itt érhető el:
              https://webgate.ec.europa.eu/odr/main/?event=main.home.show&amp;lng=HU
            </p>
            <br />
            <h2>Szerzői jogok</h2>
            <br />
            <p>
              A szerzői jogról szóló 1999. évi LXXVI. törvény (továbbiakban:
              Szjt.) 1. § (1) bekezdése értelmében a weboldal szerzői műnek
              minősül, így annak minden része szerzői jogi védelem alatt áll. Az
              Szjt. 16. § (1) bekezdése alapján tilos a weboldalon található
              grafikai és szoftveres megoldások, számítógépi programalkotások
              engedély nélküli felhasználása, illetve bármely olyan alkalmazás
              használata, amellyel a weboldal, vagy annak bármely része
              módosítható. A weboldalról és annak adatbázisából bármilyen
              anyagot átvenni a jogtulajdonos írásos hozzájárulása esetén is
              csak a weboldalra való hivatkozással, forrás feltüntetésével
              lehet. A jogtulajdonos: Aurora Film és Színház Egyesület.{" "}
            </p>
            <br />
            <h2>Részleges érvénytelenség, magatartási kódex</h2>
            <br />
            <p>
              Ha az ÁSZF valamely pontja jogilag hiányos vagy hatálytalan, attól
              még a szerződés további pontjai érvényben maradnak és a
              hatálytalan vagy hibás rész helyett a vonatkozó jogszabályok
              rendelkezései alkalmazandóak.
            </p>
            <br />
            <p>
              Az Eladónak nincs a fogyasztókkal szembeni tisztességtelen
              kereskedelmi gyakorlat tilalmáról szóló törvény szerinti
              magatartási kódexe.
            </p>
            <br />
            <h2>A termékek lényeges tulajdonságaira vonatkozó tájékoztatás</h2>
            <br />
            <p>
              A honlapon a megvásárolható termékek lényeges tulajdonságairól az
              egyes termékeknél szereplő leírásokban adunk tájékoztatást.
            </p>
            <br />
            <h2>
              Az adatbeviteli hibák javítása - Felelősség a megadott adatok
              valóságáért
            </h2>
            <br />
            <p>
              Önnek a megrendelés során a megrendelés véglegesítése előtt
              folyamatosan lehetősége van az Ön által bevitt adatok módosítására
              (a böngészőben a vissza gombr/a kattintva az előző oldal nyílik
              meg, így akkor is javíthatóak a bevitt adatok, ha Ön már a
              következő oldalra lépett). Felhívjuk a figyelmét, hogy az Ön
              felelőssége, hogy az Ön által megadott adatok pontosan kerüljenek
              bevitelre, hiszen az Ön által megadott adatok alapján kerül
              számlázásra, illetve szállításra a termék. Felhívjuk a figyelmét
              arra, hogy a rosszul megadott e-mail cím vagy a postafiókhoz
              tartozó tárhely telítettsége a visszaigazolás kézbesítésének
              hiányát eredményezheti és meggátolhatja a szerződés létrejöttét.
              Amennyiben a Vásárló véglegesítette rendelését és a megadott
              adatokban hibát fedez fel, akkor a lehető legrövidebb időn belül
              kezdeményeznie kell a rendelése módosítását. A hibás rendelés
              módosítását a megrendeléskor megadott e-mail címről küldött
              levéllel, vagy telefonhívással jelezheti Vásárló az Eladó felé.
            </p>
            <br />
            <h2>Fizetési módok</h2>
            <br />
            <h2>Simple pay bankkártyás fizetés</h2>
            <br />
            <p>
              A következő kártyákkal lehet fizetni: Mastercard, Visa, Visa
              Electron (amennyiben a kártya kibocsátója engedélyezi) Kártyás
              fizetés esetén a megrendelés házhozszállítását a fizetési
              tranzakció lezárását követően kezdjük meg. A kártyás fizetés az
              OTP Mobil Kft. biztonságos szerverén keresztül történik, a OTP
              Mobil Kft. fizetési oldalán, mely automatikusan jelenik meg a
              megrendelés jóváhagyását követően. A szükséges bankkártya adatok
              bevitele után a Fizetés gombr/a kattintva a fizetés végrehajtásra
              kerül. A fizetés megtörténtéről a rendszer visszaigazolást küld,
              majd visszairányítja a megrendelőt a webáruházba, ahol a
              fizetésről szintén visszaigazolást kap. Amennyiben az OTP Mobil
              Kft. a bankkártyás fizetést valamilyen okból visszautasítja, a
              megrendelő a rendelést a visszaigazoló emailben található linken
              keresztül is befizetheti.
              <br />A banki fizetés során felmerülő esetleges hibákért az Eladó
              nem vállal felelősséget.
            </p>
            <br />
            <h2>Átvételi módok, átvételi díjak</h2>
            <br />
            <br />
            <br />
            <p>
              <strong>Házhoz szállítás</strong>
            </p>
            <br />
            <p>A terméket a GLS futárszolgálat szállítja ki.</p>
            <p>
              Bővebb információt itt talál:
              https://gls-group.eu/HU/hu/cimzetteknek-nyujtott-szolgaltatasok
            </p>
            <p>Ezen szállítási mód díja bruttó 1590 Ft.</p>
            <br />
            <h2>Teljesítési határidő</h2>
            <br />
            <p>
              A megrendelésre vonatkozóan az általános teljesítési határidő a
              rendelés visszaigazolásától számított legfeljebb 30 nap. Az Eladó
              késedelme esetén a Vevő jogosult póthatáridőt tűzni. Ha az Eladó a
              póthatáridőn belül nem teljesít, a Vevő jogosult a szerződéstől
              elállni.
            </p>
            <br />
            <h2>Jogfenntartás, tulajdonjogi kikötés</h2>
            <br />
            <p>
              Amennyiben Ön korábban már úgy rendelt terméket, hogy azt a
              kiszállítás során nem vette át (ide nem értve azt az esetet,
              amikor elállási jogával élt), vagy a Termék nem kereste jelzéssel
              érkezett vissza az eladóhoz, az Eladó a megrendelés teljesítését a
              vételár és a szállítási költségek előre történő megfizetéséhez
              köti.
            </p>
            <br />
            <p>
              Eladó visszatarthatja a Termék átadását, ameddig nem győződik meg
              arról, hogy a Termék árának kifizetése sikeresen megtörtént az
              elektronikus fizetési megoldás használatával (ideértve azt az
              esetet is, amikor az átutalással fizetett termék esetén Vásárló a
              tagállama szerinti pénznemben utalja el a vételárat és az
              átváltás, valamint a banki jutalékok, költségek miatt Eladó nem
              kapja meg teljes mértékben a vételár és aszállítási díj összegét).
              Amennyiben a Termék ára nem került teljes mértékben kifizetésre,
              az Eladó a vételár kiegészítésére hívhatja fel a Vásárlót.
            </p>
            <br />
            <h2>Külföldre történő értékesítés</h2>
            <br />
            <p>
              Eladó nem különbözteti meg a Honlap használatával Magyarország
              területén és az azon kívül az Európai Unió területén belüli
              vásárókat. A jelen ÁSZF eltérő rendelkezése hiányában az Eladó
              Magyarország területén biztosítja a megrendelt termékek
              kiszállítását/átvételét.
            </p>
            <br />
            <p>
              A Magyarországon kívüli vásárlásra is jelen ÁSZF rendelkezései az
              irányadóak azzal, hogy a vonatkozó rendelet előírásai alapján
              jelen pont értelmezésében vevőnek az a fogyasztó minősül, aki
              valamely tagállam állampolgára, vagy valamely tagállamban
              lakóhellyel rendelkezik, vagy az a vállalkozás, amely valamely
              tagállamban letelepedési hellyel rendelkezik, és az Európai Unión
              belül kizárólag végfelhasználás céljából vásárol árut vagy vesz
              igénybe szolgáltatást, vagy ilyen szándékkal jár el. Fogyasztónak
              az a természetes személy minősül, aki olyan célból jár el, amely
              kívül esik kereskedelmi, ipari, kézműipari vagy szakmai
              tevékenysége körén.
            </p>
            <br />
            <p>
              A kommunikáció és a vásárlás nyelve elsősorban a magyar nyelv,
              Eladó nem köteles a vásárló tagállami szerinti nyelven
              kommunikálni Vásárlóval.
            </p>
            <br />
            <p>
              Eladó nem köteles megfeleljen a Vásárló tagállama szerinti nemzeti
              jogban az érintett Termékkel kapcsolatban meghatározott
              szerződésen kívüli követelményeknek, például címkézési vagy
              ágazatspecifikus követelményeknek, vagy hogy tájékoztassa a
              Vásárlót ezekről a követelményekről.
            </p>
            <br />
            <p>
              Eladó eltérő rendelkezése hiányában Magyarországi ÁFÁ-t alkalmazza
              minden Termék esetében.
            </p>
            <br />
            <p>
              Vásárló a jelen ÁSZF szerint élhet jogérvényesítési
              lehetőségeivel.
            </p>
            <br />
            <p>
              Elektronikus fizetési megoldás alkalmazása esetén a fizetés az
              Eladó által meghatározott pénznemben valósul meg,
            </p>
            <br />
            <p>
              Eladó visszatarthatja a Termék átadását ameddig nem győződik meg
              arról, hogy a Termék árának és a szállítási díjnak kifizetése
              sikeresen és maradéktalanul megtörtént az elektronikus fizetési
              megoldás használatával (ideértve azt az esetet is, amikor az
              átutalással fizetett termék esetén Vásárló a tagállama szerinti
              pénznemben utalja el a vételárat (szállítási díjat) és az
              átváltás, valamint a banki jutalékok, költségek miatt Eladó nem
              kapja meg teljes mértékben a vételár összegét). Amennyiben a
              Termék ára nem került teljes mértékben kifizetésre, az Eladó a
              vételár kiegészítésére hívhatja fel a Vásárlót.
            </p>
            <br />
            <p>
              Eladó a Termék átadása érdekében a magyar vásárlókat megillető
              átadási lehetőségeket biztosítja a nem magyarországi vásárlóknak
              is.
            </p>
            <br />
            <p>
              Amennyiben Vásárló az ÁSZF szerint kérheti a Termék szállítását
              Magyarország területére, vagy bármely más Európai Uniós tagállam
              területére, ezt kérheti a nem magyarországi vásárló is bármely az
              ÁSZF-ben megjelölt szállítási módon.
            </p>
            <br />
            <p>
              Amennyiben Vásárló az ÁSZF szerint választhatja a Termék személyes
              átvételét az Eladónál, ezzel élhet a nem magyarországi vásárló is.
            </p>
            <br />
            <p>
              Egyebekben a Vásárló kérheti, hogy a Termék szállítását saját
              költségén oldhassa meg külföldre. Magyar vásárlót ez a jog nem
              illet meg.
            </p>
            <br />
            <p>
              Eladó a szállítási díj megfizetését követően teljesíti a
              megrendelést, amennyiben Vásárló a szállítási díjat nem fizeti meg
              Eladó számára, vagy a saját szállítást nem oldja meg az előre
              egyezetetett időpontig, Eladó a szerződést felmondja és az előre
              megfizetett vételárat visszafizeti Vásárló számára..
            </p>
            <br />
            <br />
            <h1>
              FOGYASZTÓI TÁJÉKOZTATÓ A 45/2014. (II. 26.) KORM. RENDELET ALAPJÁN
            </h1>
            <br />
            <p>Tartalomjegyzék</p>
            <br />
            <ul>
              <li>
                Fogyasztói tájékoztató
                <br />
                <ul>
                  <li>
                    Elállási jog
                    <br />
                  </li>
                  <li>Szavatossági jogok</li>
                </ul>
              </li>
            </ul>
            <br />
            <h2>Tájékoztató a fogyasztó vevőt megillető elállási jogról</h2>
            <br />
            <p>
              Fogyasztónak a Ptk. 8:1. § 1. bekezdés 3. pontja szerint csak a
              szakmája, önálló foglalkozása vagy üzleti tevékenysége körén kívül
              eljáró természetes személy minősül, így jogi személyek nem
              élhetnek az indokolás nélküli elállási joggal!
            </p>
            <br />
            <p>
              A fogyasztót a 45/2014. (II. 26.) Korm. rendelet 20. § szerint
              megilleti az indokolás nélküli elállás joga. A fogyasztó az
              elállási jogát
            </p>
            <br />
            <p>a) termék adásvételére irányuló szerződés esetén</p>
            <br />
            <p>aa) a terméknek,</p>
            <br />
            <p>
              ab) több termék adásvételekor, ha az egyes termékek szolgáltatása
              eltérő időpontban történik,az utoljára szolgáltatott terméknek,
            </p>
            <p>
              a fogyasztó vagy az általa megjelölt, a fuvarozótól eltérő
              harmadik személy általi átvételének napjától számított határidőn
              belül gyakorolhatja, mely határidő 14 nap.
            </p>
            <br />
            <p>
              A jelen pontban foglaltak nem érintik a fogyasztó azon jogát, hogy
              az e pontban meghatározott elállási jogát a szerződés megkötésének
              napja és a termék átvételének napja közötti időszakban is
              gyakorolja.
            </p>
            <br />
            <p>
              Ha a szerződés megkötésére a fogyasztó tett ajánlatot, a
              fogyasztót a szerződés megkötése előtt megilleti az ajánlat
              visszavonásának joga, ami a szerződés megkötésére kiterjedő
              ajánlati kötöttséget megszünteti.
            </p>
            <br />
            <h2>
              Elállási nyilatkozat, a fogyasztót megillető elállási vagy
              felmondási jog gyakorlása
            </h2>
            <br />
            <p>
              A fogyasztó a 45/2014. (II. 26.) Korm. rendelet 20. §-ban
              biztosított jogát az erre vonatkozó egyértelmű nyilatkozat útján
              gyakorolhatja. Írásban történő elállás esetén elegendő az elállási
              nyilatkozatot elküldeni 14 napon belül.
            </p>
            <br />
            <br />
            <h2>A fogyasztó elállási nyilatkozatának érvényessége</h2>
            <br />
            <p>
              Az elállási jogot határidőben érvényesítettnek kell tekinteni, ha
              a fogyasztó nyilatkozatát határidőn belül elküldi. A határidő 14
              nap.
            </p>
            <br />
            <p>
              A fogyasztót terheli annak bizonyítása, hogy az elállás jogát e
              rendelkezéssel összhangban gyakorolta.
            </p>
            <br />
            <p>
              Az Eladó a fogyasztó elállási nyilatkozatát annak megérkezését
              követően köteles elektronikus adathordozón visszaigazolni.{" "}
            </p>
            <br />
            <h2>Az Eladó kötelezettségei a fogyasztó elállása esetén</h2>
            <br />
            <h2>Az Eladó visszatérítési kötelezettsége</h2>
            <br />
            <p>
              Ha a fogyasztó a 45/2014. (II. 26.) Korm. rendelet 22. §-nak
              megfelelően eláll a szerződéstől, az Eladó legkésőbb az elállásról
              való tudomásszerzésétől számított tizennégy napon belül
              visszatéríti a fogyasztó által ellenszolgáltatásként megfizetett
              teljes összeget, ideértve a teljesítéssel összefüggésben felmerült
              költségeket, így a szállítási díjat is. Felhívjuk a figyelmét,
              hogy ez a rendelkezés nem vonatkozik a legkevésbé költséges
              szokásos fuvarozási módtól eltérő fuvarozási mód választásával
              okozott többletköltségekre.
            </p>
            <br />
            <h2>Az Eladó visszatérítési kötelezettségének módja</h2>
            <br />
            <p>
              A 45/2014. (II. 26.) Korm. rendelet 22. §-nak megfelelő elállás
              vagy felmondás esetén az Eladó a fogyasztónak visszajáró összeget
              a fogyasztó által igénybe vett fizetési móddal megegyező módon
              téríti vissza. A fogyasztó kifejezett beleegyezése alapján az
              Eladó a visszatérítésre más fizetési módot is alkalmazhat, de a
              fogyasztót ebből adódóan semmilyen többletdíj nem terhelheti. A
              Fogyasztó által hibásan és/vagy pontatlanul megadott
              bankszámlaszám vagy postai cím következtében történő késedelem
              miatt az Eladó-t felelősség nem terheli.
            </p>
            <br />
            <h2>Többletköltségek</h2>
            <br />
            <p>
              Ha a fogyasztó kifejezetten a legkevésbé költséges szokásos
              fuvarozási módtól eltérő fuvarozási módot választ, az Eladó nem
              köteles visszatéríteni az ebből eredő többletköltségeket. Ilyen
              esetben a feltüntetett általános szállítási díjtételek erejéig áll
              fenn visszatérítési kötelezettségünk.{" "}
            </p>
            <br />
            <h2>Visszatartási jog</h2>
            <br />
            <p>
              Az Eladó mindaddig visszatarthatja a fogyasztónak visszajáró
              összeget, amíg a fogyasztó a terméket vissza nem szolgáltatta,
              vagy kétséget kizáróan nem igazolta, hogy azt visszaküldte; a
              kettő közül a korábbi időpontot kell figyelembe venni. Utánvéttel
              vagy portósan feladott küldeményeket nem áll módunkban elfogadni.{" "}
            </p>
            <br />
            <h2>A fogyasztó kötelezettségei elállása vagy felmondása esetén</h2>
            <br />
            <br />
            <h2>A termék visszaszolgáltatása</h2>
            <br />
            <p>
              Ha a fogyasztó a 45/2014. (II. 26.) Korm. rendelet 22. §-nak
              megfelelően eláll a szerződéstől, köteles a terméket
              haladéktalanul, de legkésőbb az elállás közlésétől számított
              tizennégy napon belül visszaküldeni, illetve az Eladónak vagy az
              Eladó által a termék átvételére meghatalmazott személynek átadni.
              A visszaküldés határidőben teljesítettnek minősül, ha a fogyasztó
              a terméket a határidő lejárta előtt elküldi.
            </p>
            <br />
            <h2>
              A termék visszaszolgáltatásával kapcsolatos közvetlen költségek
              viselése
            </h2>
            <br />
            <p>
              A fogyasztó viseli a termék visszaküldésének közvetlen költségét.
              A terméket az Eladó címére kell visszaküldeni. Ha a fogyasztó a
              teljesítés megkezdését követően felmondja az üzlethelyiségen kívül
              vagy távollévők között kötött - szolgáltatás nyújtásra vonatkozó -
              szerződést, köteles a felmondás vállalkozással való közlése
              időpontjáig teljesített szolgáltatással arányos díjat a
              vállalkozás számára megfizetni. A fogyasztó által arányosan
              fizetendő összeget a szerződésben megállapított ellenszolgáltatás
              adóval növelt teljes összege alapján kell megállapítani. Ha a
              fogyasztó bizonyítja, hogy az ily módon megállapított teljes
              összeg túlzottan magas, az arányos összeget a szerződés
              megszűnésének időpontjáig teljesített szolgáltatások piaci értéke
              alapján kell kiszámítani. Kérjük vegye figyelembe, hogy utánvéttel
              vagy portósan visszaküldött terméket nem áll módunkban átvenni.
            </p>
            <br />
            <h2>Fogyasztó felelőssége az értékcsökkenésért</h2>
            <br />
            <p>
              A fogyasztó a termék jellegének, tulajdonságainak és működésének
              megállapításához szükséges használatot meghaladó használatból
              eredő értékcsökkenésért felel.
            </p>
            <br />
            <h2>Az elállási jog az alábbi esetekben nem gyakorolható</h2>
            <br />
            <p>
              Az Eladó kifejezetten felhívja az Ön figyelmét, hogy Ön nem
              gyakorolhatja elállási jogát a 45/2014 (II.26.) Korm. Rendelet 29.
              §. (1) bekezdésében foglalt esetekben:​
            </p>
            <ol>
              <li>
                a szolgáltatás nyújtására irányuló szerződés esetében a
                szolgáltatás egészének teljesítését követően, ha a vállalkozás a
                teljesítést a fogyasztó kifejezett, előzetes beleegyezésével
                kezdte meg, és a fogyasztó tudomásul vette, hogy a szolgáltatás
                egészének teljesítését követően felmondási jogát elveszíti;
                <br />
              </li>
              <li>
                olyan termék vagy szolgáltatás tekintetében, amelynek ára,
                illetve díja a pénzpiac vállalkozás által nem befolyásolható, az
                elállási jog gyakorlására meghatározott határidő alatt is
                lehetséges ingadozásától függ;
                <br />
                <br />
              </li>
              <li>
                olyan nem előre gyártott termék esetében, amelyet a fogyasztó
                utasítása alapján vagy kifejezett kérésére állítottak elő, vagy
                olyan termék esetében, amelyet egyértelműen a fogyasztó
                személyére szabtak;
                <br />
              </li>
              <li>
                romlandó vagy minőségét rövid ideig megőrző termék tekintetében;
                <br />
              </li>
              <li>
                olyan zárt csomagolású termék tekintetében, amely
                egészségvédelmi vagy higiéniai okokból az átadást követő
                felbontása után nem küldhető vissza;
                <br />
              </li>
              <li>
                olyan termék tekintetében, amely jellegénél fogva az átadást
                követően elválaszthatatlanul vegyül más termékkel;
                <br />
              </li>
              <li>
                olyan alkoholtartalmú ital tekintetében, amelynek tényleges
                értéke a vállalkozás által nem befolyásolható módon a piaci
                ingadozásoktól függ, és amelynek áráról a felek az adásvételi
                szerződés megkötésekor állapodtak meg, azonban a szerződés
                teljesítésére csak a megkötéstől számított harmincadik napot
                követően kerül sor;
                <br />
              </li>
              <li>
                olyan vállalkozási szerződés esetében, amelynél a vállalkozás a
                fogyasztó kifejezett kérésére keresi fel a fogyasztót sürgős
                javítási vagy karbantartási munkálatok elvégzése céljából;
                <br />
              </li>
              <li>
                lezárt csomagolású hang-, illetve képfelvétel, valamint
                számítógépes szoftver példányának adásvétele tekintetében, ha az
                átadást követően a fogyasztó a csomagolást felbontotta;
                <br />
              </li>
              <li>
                hírlap, folyóirat és időszaki lap tekintetében, az előfizetéses
                szerződések kivételével;
                <br />
              </li>
              <li>
                nyilvános árverésen megkötött szerződések esetében;
                <br />
              </li>
              <li>
                lakáscélú szolgáltatás kivételével szállásnyújtásra irányuló
                szerződés, fuvarozás, személygépjármű-kölcsönzés, étkeztetés
                vagy szabadidős tevékenységekhez kapcsolódó szolgáltatásra
                irányuló szerződés esetében, ha a szerződésben meghatározott
                teljesítési határnapot vagy határidőt kötöttek ki;
                <br />
              </li>
              <li>
                a nem tárgyi adathordozón nyújtott digitális adattartalom
                tekintetében, ha a vállalkozás a fogyasztó kifejezett, előzetes
                beleegyezésével kezdte meg a teljesítést, és a fogyasztó e
                beleegyezésével egyidejűleg nyilatkozott annak tudomásul
                vételéről, hogy a teljesítés megkezdését követően elveszíti az
                elállási jogát.
              </li>
            </ol>
            <br />
            <h2>Kellékszavatosság, termékszavatosság, jótállás</h2>
            <br />
            <p>
              A fogyasztói tájékoztató jelen pontja a 45/2014 (II.26.) Korm.
              rendelet 9. § (3) bekezdése felhatalmazása alapján a 45/2014
              (II.26.) Korm. rendelet 3. számú melléklete alkalmazásával
              készült.
            </p>
            <br />
            <h2>Kellékszavatosság</h2>
            <br />
            <h3>MILYEN ESETBEN ÉLHET ÖN A KELLÉKSZAVATOSSÁGI JOGÁVAL?</h3>
            <br />
            <p>
              Ön az Eladó hibás teljesítése esetén az Eladóval szemben
              kellékszavatossági igényt érvényesíthet a Polgári Törvénykönyv
              szabályai szerint.
            </p>
            <br />
            <h3>
              MILYEN JOGOK ILLETIK MEG ÖNT KELLÉKSZAVATOSSÁGI IGÉNYE ALAPJÁN?
            </h3>
            <br />
            <p>
              Ön – választása szerint–az alábbi kellékszavatossági igényekkel
              élhet:
            </p>
            <br />
            <p>
              Kérhet kijavítást vagy kicserélést, kivéve, ha az ezek közül az Ön
              által választott igény teljesítése lehetetlen vagy az Eladó
              számára más igénye teljesítéséhez képest aránytalan
              többletköltséggel járna. Ha a kijavítást vagy a kicserélést nem
              kérte, illetve nem kérhette, úgy igényelheti az ellenszolgáltatás
              arányos leszállítását vagy a hibát az Eladó költségére Ön is
              kijavíthatja, illetve mással kijavíttathatja vagy – végső esetben
              – a szerződéstől is elállhat.
            </p>
            <br />
            <p>
              Választott kellékszavatossági jogáról egy másikra is áttérhet, az
              áttérés költségét azonban Ön viseli, kivéve, ha az indokolt volt,
              vagy arra az Eladó adott okot.
            </p>
            <br />
            <h3>
              MILYEN HATÁRIDŐBEN ÉRVÉNYESÍTHETI ÖN KELLÉKSZAVATOSSÁGI IGÉNYÉT?
            </h3>
            <br />
            <p>
              Ön köteles a hibát annak felfedezése után haladéktalanul, de nem
              később, mint a hiba felfedezésétől számított kettő hónapon belül
              közölni. Ugyanakkor felhívjuk a figyelmét, hogy a szerződés
              teljesítésétől számított két éves elévülési határidőn túl
              kellékszavatossági jogait már nem érvényesítheti.
            </p>
            <br />
            <p>
              Ha a fogyasztó és a vállalkozás közötti szerződés tárgya használt
              dolog, a felek rövidebb elévülési időben is megállapodhatnak; egy
              évnél rövidebb elévülési határidő ebben az esetben sem köthető ki
              érvényesen.{" "}
            </p>
            <br />
            <h3>KIVEL SZEMBEN ÉRVÉNYESÍTHETI KELLÉKSZAVATOSSÁGI IGÉNYÉT?</h3>
            <br />
            <p>
              Ön az Eladóval szemben érvényesítheti kellékszavatossági igényét.
            </p>
            <br />
            <h3>
              MILYEN EGYÉB FELTÉTELE VAN KELLÉKSZAVATOSSÁGI JOGAI
              ÉRVÉNYESÍTÉSÉNEK?
            </h3>
            <br />
            <p>
              A teljesítéstől számított hat hónapon belül a kellékszavatossági
              igénye érvényesítésének a hiba közlésén túl nincs egyéb feltétele,
              ha Ön igazolja, hogy a terméket, illetve a szolgáltatást az Eladó
              nyújtotta. A teljesítéstől számított hat hónap eltelte után
              azonban már Ön köteles bizonyítani, hogy az Ön által felismert
              hiba már a teljesítés időpontjában is megvolt.
            </p>
            <br />
            <p>
              Használt termékek esetén alapesetben az általános szabályoktól
              eltérően alakulnak a szavatossági, jótállási jogok. A használt
              termékek esetében is beszélhetünk hibás teljesítésről, azonban
              figyelembe kell venni azokat a körülményeket, amelyek alapján a
              Vásárló bizonyos hibák előfordulására számíthatott. Az avulás
              folytán ugyanis egyes hibák jelentkezései egyre gyakoribbakká
              válnak, amelyek következtében azt, hogy egy használt termék
              ugyanolyan minőséggel rendelkezhet, mint egy újonnan vásárolt, nem
              lehet feltételezni. Ennek alapján a Vásárló csak az olyan
              hiányosságok tekintetében érvényesítheti a szavatossági jogait,
              amelyek a használtságból eredő hibákon felüliek, és azoktól
              függetlenül keletkeztek. Ha a használt termék hibás és erről a
              Fogyasztónak minősülő Vásárló a vásárláskor tájékoztatást kapott,
              az ismert hiba vonatkozásában a Szolgáltatónak nincs felelőssége.
            </p>
            <br />
            <h2>Termékszavatosság</h2>
            <br />
            <h3>MILYEN ESETBEN ÉLHET ÖN A TERMÉKSZAVATOSSÁGI JOGÁVAL?</h3>
            <br />
            <p>
              Ingó dolog (termék) hibája esetén Ön – választása szerint – a
              kellékszavatossági igényt vagy termékszavatossági igényt
              érvényesíthet.
            </p>
            <br />
            <h3>
              MILYEN JOGOK ILLETIK MEG ÖNT TERMÉKSZAVATOSSÁGI IGÉNYE ALAPJÁN?
            </h3>
            <br />
            <p>
              Termékszavatossági igényként Ön kizárólag a hibás termék
              kijavítását vagy kicserélését kérheti.
            </p>
            <br />
            <h3>MILYEN ESETBEN MINŐSÜL A TERMÉK HIBÁSNAK?</h3>
            <br />
            <p>
              A termék akkor hibás, ha az nem felel meg a forgalomba hozatalakor
              hatályos minőségi követelményeknek vagy pedig, ha nem rendelkezik
              a gyártó által adott leírásban szereplő tulajdonságokkal.
            </p>
            <br />
            <h3>
              MILYEN HATÁRIDŐBEN ÉRVÉNYESÍTHETI ÖN TERMÉKSZAVATOSSÁGI IGÉNYÉT?
            </h3>
            <br />
            <p>
              Termékszavatossági igényét Ön a termék gyártó általi forgalomba
              hozatalától számított két éven belül érvényesítheti. E határidő
              elteltével e jogosultságát elveszti.
            </p>
            <br />
            <h3>
              KIVEL SZEMBEN ÉS MILYEN EGYÉB FELTÉTELLEL ÉRVÉNYESÍTHETI
              TERMÉKSZAVATOSSÁGI IGÉNYÉT?
            </h3>
            <br />
            <p>
              Termékszavatossági igényét kizárólag az ingó dolog gyártójával
              vagy forgalmazójával szemben gyakorolhatja. A termék hibáját
              termékszavatossági igény érvényesítése esetén Önnek kell
              bizonyítania.
            </p>
            <br />
            <h3>
              A GYÁRTÓ (FORGALMAZÓ) MILYEN ESETBEN MENTESÜL TERMÉKSZAVATOSSÁGI
              KÖTELEZETTSÉGE ALÓL?
            </h3>
            <br />
            <p>
              A gyártó (forgalmazó) kizárólag akkor mentesül termékszavatossági
              kötelezettsége alól, ha bizonyítani tudja, hogy:
            </p>
            <br />
            <ul>
              <li>
                a terméket nem üzleti tevékenysége körében gyártotta, illetve
                hozta forgalomba, vagy
                <br />
              </li>
              <li>
                a hiba a tudomány és a technika állása szerint a forgalomba
                hozatal időpontjában nem volt felismerhető vagy
                <br />
              </li>
              <li>
                a termék hibája jogszabály vagy kötelező hatósági előírás
                alkalmazásából ered.
              </li>
            </ul>
            <br />
            <p>
              A gyártónak (forgalmazónak) a mentesüléshez elegendő egy okot
              bizonyítania.
            </p>
            <br />
            <p>
              Felhívom figyelmét, hogy ugyanazon hiba miatt kellékszavatossági
              és termékszavatossági igényt egyszerre, egymással párhuzamosan nem
              érvényesíthet. Termékszavatossági igényének eredményes
              érvényesítése esetén azonban a kicserélt termékre, illetve
              kijavított részre vonatkozó kellékszavatossági igényét a gyártóval
              szemben érvényesítheti.
            </p>
            <br />
            <h2>Jótállás</h2>
            <br />
            <h3>MILYEN ESETBEN ÉLHET ÖN A JÓTÁLLÁSI JOGÁVAL?</h3>
            <br />
            <p>
              Az egyes tartós fogyasztási cikkekre vonatkozó kötelező
              jótállásról szóló 151/2003. (IX. 22.) Korm. rendelet alapján az
              Eladó jótállásra köteles a rendelet 1. számú mellékletében
              felsorolt új tartós fogyasztási cikkek (pl.: műszaki cikkek,
              szerszámok, gépek), valamint az ott meghatározott körben azok
              tartozékai és alkotórészei (a továbbiakban - jelen pontban -
              együtt fogyasztási cikként hivatkozva) eladása esetén.
            </p>
            <br />
            <h3>
              ÖNT MILYEN JOGOK ÉS MILYEN HATÁRIDŐN BELÜL ILLETIK MEG JÓTÁLLÁS
              ALAPJÁN?
            </h3>
            <h3>
              <br />
            </h3>
            <h3>JÓTÁLLÁSI JOGOK</h3>
            <br />
            <p>Ön – választása szerint–az alábbi jótállási igényekkel élhet:</p>
            <br />
            <p>
              Kérhet kijavítást vagy kicserélést, kivéve, ha az ezek közül az Ön
              által választott igény teljesítése lehetetlen vagy az Eladó
              számára más igénye teljesítéséhez képest aránytalan
              többletköltséggel járna. Ha a kijavítást vagy a kicserélést nem
              kérte, illetve nem kérhette, úgy igényelheti az ellenszolgáltatás
              arányos leszállítását vagy a hibát az Eladó költségére Ön is
              kijavíthatja, illetve mással kijavíttathatja vagy – végső esetben
              – a szerződéstől is elállhat.
            </p>
            <br />
            <p>
              A Vásárló a kijavítás iránti igényét választása szerint az Eladó
              székhelyén, bármely telephelyén, fióktelepén és az Eladó által a
              jótállási jegyen feltüntetett javítószolgálatnál közvetlenül is
              érvényesítheti.
            </p>
            <br />
            <p>
              A kijavítási igény kezelésére a kötelező jótállásról szóló
              151/2003. (IX. 22.) Korm. rendelet azonban speciális szabályokat
              határozott meg, amelyeket a „Jótállási igény kezelésével
              kapcsolatos szabályok” pont alatt mutatunk be.
            </p>
            <br />
            <h3>ÉRVÉNYESÍTÉSI HATÁRIDŐ</h3>
            <br />
            <p>
              A jótállási igény a jótállás időtartama alatt érvényesíthető, a
              jótállás időtartama a 151/2003. (IX. 22.) Korm. rendelet szerint:
            </p>
            <br />
            <ol>
              <li>
                10 000 forintot elérő, de 100 000 forintot meg nem haladó
                eladási ár esetén egy év,
                <br />
              </li>
              <li>
                100 000 forintot meghaladó, de 250 000 forintot meg nem haladó
                eladási ár esetén két év,
                <br />
              </li>
              <li>250 000 forint eladási ár felett három év.</li>
            </ol>
            <br />
            <p>
              E határidők elmulasztása jogvesztéssel jár, azonban a fogyasztási
              cikk kijavítása esetén a jótállás időtartama meghosszabbodik a
              javításra átadás napjától kezdve azzal az idővel, amely alatt a
              Vásárló a fogyasztási cikket a hiba miatt rendeltetésszerűen nem
              használhatta.
            </p>
            <br />
            <p>
              A jótállási határidő a fogyasztási cikk Vásárló részére történő
              átadásakor indul, vagy ha az üzembe helyezést az Eladó, vagy annak
              megbízottja végzi, az üzembe helyezés napjával kezdődik.
            </p>
            <br />
            <p>
              Ha a Vásárló a fogyasztási cikket az átadástól számított hat
              hónapon túl helyezteti üzembe, akkor a jótállási határidő kezdő
              időpontja a fogyasztási cikk átadásának napja.
            </p>
            <br />
            <h3>JÓTÁLLÁSI IGÉNY KEZELÉSÉVEL KAPCSOLATOS SZABÁLYOK</h3>
            <br />
            <p>
              A kijavítás kezelésekor az Eladónak törekedni kell arra, hogy a
              kijavítást 15 napon belül elvégezze. A kijavításra nyitva álló
              határidő a fogyasztási cikk átvételekor indul.
            </p>
            <br />
            <p>
              Ha a kijavítás vagy a kicserélés időtartama a tizenöt napot
              meghaladja, akkor az Eladó a Vásárlót tájékoztatni köteles a
              kijavítás vagy a csere várható időtartamáról.
            </p>
            <br />
            <p>
              Ha a jótállási időtartam alatt a fogyasztási cikk első alkalommal
              történő javítása során az Eladó részéről megállapítást nyer, hogy
              a fogyasztási cikk nem javítható, a vásárló eltérő rendelkezése
              hiányában az Eladó köteles a fogyasztási cikket nyolc napon belül
              kicserélni. Ha a fogyasztási cikk cseréjére nincs lehetőség, az
              Eladó köteles a fogyasztó által bemutatott, a fogyasztási cikk
              ellenértékének megfizetését igazoló bizonylaton - az általános
              forgalmi adóról szóló törvény alapján kibocsátott számlán vagy
              nyugtán - feltüntetett vételárat nyolc napon belül a vásárló
              részére visszatéríteni.
            </p>
            <br />
            <p>
              Vásárló az ÁSZF elfogadásával hozzájárul, hogy számára a
              tájékoztatást elektronikus úton vagy a Vásárló általi átvétel
              igazolására alkalmas más módon is megvalósulhasson.
            </p>
            <br />
            <p>
              Amennyiben az Eladó nem tudja a fogyasztási cikket 30 napon belül
              kijavítani:
            </p>
            <br />
            <ul>
              <li>
                ha a Vásárló ehhez hozzájárult, számára a kijavítás teljesíthető
                későbbi határidőben, vagy
                <br />
              </li>
              <li>
                amennyiben a Vásárló nem járul hozzá a kijavítás későbbi
                teljesítéséhez, vagy ezzel kapcsolatban nem nyilatkozott,
                számára a fogyasztási cikket a harmincnapos határidő
                eredménytelen elteltét követő nyolc napon belül ki kell
                cserélni, vagy
                <br />
              </li>
              <li>
                amennyiben a Vásárló nem járul hozzá a kijavítás későbbi
                teljesítéséhez, vagy ezzel kapcsolatban nem nyilatkozott, de a
                fogyasztási cikk cseréjére sincs lehetőség, a fogyasztási cikk
                számláján, vagy nyugtáján szereplő eladási árat kell számára a
                harmincnapos határidő eredménytelen elteltét követő nyolc napon
                belül visszatéríteni.
              </li>
            </ul>
            <br />
            <p>
              Amennyiben a fogyasztási cikk 4. alkalommal hibásodik meg a
              Vásárló jogosult:
            </p>
            <br />
            <ul>
              <li>
                az Eladó felé kijavítási igényel fordulni, vagy
                <br />
              </li>
              <li>
                a kijavítási igény helyett a Polgári Törvénykönyvről szóló 2013.
                évi V. törvény 6:159. § (2) bekezdés b) pontja alapján a vételár
                arányos leszállítását kérni az Eladótól, vagy
                <br />
              </li>
              <li>
                a kijavítási igény helyett a Polgári Törvénykönyvről szóló 2013.
                évi V. törvény 6:159. § (2) bekezdés b) pontja alapján a
                fogyasztási cikket az Eladó költségére kijavítani vagy mással
                kijavíttatani, vagy
              </li>
            </ul>
            <br />
            <p>
              amennyiben a Vásárló ezen jogaival (kijavítás, árleszállítás és
              mással kijavíttatás az Eladó költségére) nem él, vagy ezekkel
              kapcsolatban nem nyilatkozott, számára 8. napon belül a
              fogyasztási cikket ki kell cserélni, ha a fogyasztási cikk
              cseréjére nincs lehetőség, a fogyasztási cikk számláján, vagy
              nyugtáján szereplő eladási árat kell nyolc napon belül
              visszatéríteni számára.
            </p>
            <br />
            <h3>KIVÉTELEK</h3>
            <br />
            <p>
              A „Jótállási igény kezelésével kapcsolatos szabályok” pont alatt
              írt előírások az elektromos kerékpárra, elektromos rollerre,
              quadra, motorkerékpárra, segédmotoros kerékpárra,
              személygépkocsira, lakóautóra, lakókocsira, utánfutós lakókocsira,
              utánfutóra, valamint a motoros vízi járműre nem vonatkoznak.
            </p>
            <br />
            <p>
              Ezen termékek esetében is köteles törekedni azonban az Eladó arra,
              hogy a kijavítási igényt 15 napon belül teljesítse.
            </p>
            <br />
            <p>
              Ha a kijavítás vagy a kicserélés időtartama a tizenöt napot
              meghaladja, akkor az Eladó a Vásárlót tájékoztatni köteles a
              kijavítás vagy a csere várható időtartamáról.
            </p>
            <br />
            <h3>MI A VISZONYA A JÓTÁLLÁSNAK MÁS SZAVATOSSÁGI JOGOKKAL?</h3>
            <br />
            <p>
              A jótállás a szavatossági jogok (termék és kellékszavatosság)
              mellett érvényesül, alapvető különbség az általános szavatossági
              jogok és a jótállás között, hogy a jótállás esetén a fogyasztónak
              kedvezőbb a bizonyítási teher.{" "}
            </p>
            <br />
            <p>
              A 151/2003 Korm. Rendelet szerinti kötelező jótállás alá eső
              rögzített bekötésű, illetve a 10 kg-nál súlyosabb, vagy
              tömegközlekedési eszközön kézi csomagként nem szállítható
              fogyasztási cikket - a járművek kivételével - az üzemeltetés
              helyén kell megjavítani. Ha a kijavítás az üzemeltetés helyén nem
              végezhető el, a le- és felszerelésről, valamint az el- és
              visszaszállításról a vállalkozás, vagy - a javítószolgálatnál
              közvetlenül érvényesített kijavítás iránti igény esetén - a
              javítószolgálat gondoskodik.
            </p>
            <br />
            <p>
              Az eladó vállalása a kötelező jótállás időtartama alatt nem
              tartalmazhat a fogyasztóra nézve olyan feltételeket, amelyek
              hátrányosabbak azoknál a jogoknál, amelyeket a kötelező jótállás
              szabályai biztosítanak. Ezt követően azonban az önkéntes jótállás
              feltételei szabadon állapíthatóak meg, azonban a jótállás ebben az
              esetben sem érintheti a fogyasztó jogszabályból eredő -így köztük
              a kellékszavatosságon alapuló jogainak fennállását.
            </p>
            <br />
            <h3>HÁROM MUNKANAPON BELÜLI CSEREIGÉNY</h3>
            <br />
            <p>
              Webáruházon keresztüli értékesítés esetén is érvényesül a három
              munkanapon belüli csereigény intézménye. Három munkanapon belüli
              csereigényt a 151/2003. (IX. 22.) Korm. rendelet alá tartozó új
              tartós fogyasztási cikkek esetében lehet érvényesíteni, amennyiben
              a hiba a rendeltetésszerű használatot akadályozza. A csereigényt
              az üzembe helyezéstől/vásárlástól számított 3 munkanapon belül
              lehet érvényesíteni.
            </p>
            <br />
            <h3>MIKOR MENTESÜL AZ ELADÓ A JÓTÁLLÁSI KÖTELEZETTSÉGE ALÓL?</h3>
            <br />
            <p>
              Az Eladó a jótállási kötelezettsége alól csak abban az esetben
              mentesül, ha bizonyítja, hogy a hiba oka a teljesítés után
              keletkezett.
            </p>
            <br />
            <p>
              Felhívjuk a figyelmét, hogy ugyanazon hiba miatt
              kellékszavatossági és jótállási igényt, illetve termékszavatossági
              és jótállási igényt egyszerre, egymással párhuzamosan nem
              érvényesíthet, egyébként viszont Önt a jótállásból fakadó jogok a
              szavatossági jogosultságoktól függetlenül megilletik.
            </p>
            <br />
            <ul>
              <li>
                Az Eladó fenntartja magának a jogot, hogy a Vevő megrendelését
                indokolt esetben visszautasítsa, így különösen valótlan vagy
                hiányos adatok megadása, illetve az online értékesítési rendszer
                vagy a Jegyekkel történő bármilyen visszaélés esetén.
              </li>
            </ul>
            <p>
              <br />
              <br />
            </p>
          </div>
          <div>
            <p>Név: Aurora Film és Színház Egyesület</p>
            <p>Székhely: 1188. Budapest, Juharos sor 18. 2\\12.</p>
            <p>Levelezési cím: 1188. Budapest, Juharos sor 18. 2\\12.</p>
            <p>Nyilvántartásba vevő hatóság: Budapest Környéki Törvényszék</p>
            <p>Adószám: 18904325-1-43</p>
            <p>Elnök: Kreszner Zsófia</p>
            <p>Telefonszám: +36 70 427 13 14</p>
            <p>E-mail: info@enszinhazam.hu</p>
            <p>Honlap: https://enszinhazam.hu</p>
            <p>Bankszámlaszám: 12100011-17687948</p>
            <br />

            <p>
              Kérjük, hogy a jegyvásárlás idején vegye figyelembe azt, hogy a
              rendezvények látogatásának szabályai a jegyvásárlás és a
              rendezvény időpontja között is megváltozhatnak.
            </p>
            <br />
            <p>
              Az adott Előadás megtekintéséhez a Látogatónak az Előadás
              kezdetekor a megtekinteni kívánt Előadásra érvényes Jeggyel vagy
              Bérlettel kell rendelkeznie. A Látogató a Jegyét vagy Bérletét
              köteles annak érvényességének megvizsgálása céljából az erre
              feljogosított személynek bemutatni.
            </p>
            <br />
            <p>
              1. Az Előadás látogatására feljogosító Jegy szabadon átruházható,
              de a személyhez kapcsolódó kedvezmény csak ugyanezen jogosultság
              megléte esetén érvényes az új tulajdonosra. Az átruházással a
              Jegyet vagy Bérletet megszerző Látogató elfogadja, hogy az Előadás
              lebonyolítására a jelen ÁSZF rendelkezései szerint kerül sor.
            </p>
            <br />
            <p>
              2. A Bérlet vagy Jegy a feltüntetett Előadásra egyszeri alkalommal
              történő belépésre jogosítja fel annak felmutatóját, egy főt.
            </p>
            <br />
            <p>
              3. A Vásárló a "Megrendelés” gomb megnyomásával fogadja el a
              Szolgáltatásra vonatkozó szerződés feltételeit, egyben jelen ÁSZF
              rendelkezéseit.
            </p>
            <br />
            <p>
              4. Megrendelést követően e-mailben értesítünk arról, hogy jegyedet
              a profilodból tudod letölteni. Az ehhez kapcsolódó számlát külön
              e-mailben küldjük el.
            </p>
            <br />
            <p>
              5. Elveszett, megrongálódott, megsemmisült Jegyek pótlására nincs
              lehetőség.
            </p>
            <br />
            <p>
              6. Az érvényes Jeggyel rendelkező Látogató jogosult az adott
              Előadás játszóhelyén tartózkodni, a kiszolgáló létesítményeket,
              berendezéseket használni, az Előadáshoz kapcsolódó
              szolgáltatásokat igénybe venni. A Színház az egyes szolgáltatások
              igénybevételét jogosult díjazáshoz kötni.
            </p>
            <br />
            <p>
              7. A Látogató a Jegyén feltüntetett sor és székszámnak megfelelő
              helyen köteles helyet foglalni. A megfelelő ülőhely elfoglalásában
              ültetők segítik a Látogatók eligazodását. Amennyiben a Látogató a
              helyét nem találja, úgy köteles az ültetők segítségét igénybe
              venni. Esetekben a jegyek nem helyhez kötötte, ebben az esetekben
              érkezési sorrendben, tetszőlegesen választhatják ki helyeiket.
            </p>
            <br />
            <p>
              8. Az Előadásra szóló jegyek egy része olyan helyre szól, ahonnan
              a színpadi rálátás korlátozott. A Jegy vásárlásakor a Színház
              tájékoztatást ad erről a körülményről, továbbá ezen Jegyek
              ellenértékének meghatározása is ennek figyelembevételével
              történik. Az Előadást követően a korlátozott színpadi rálátás
              miatt nincs helye panasznak, a Színház nem téríti vissza a jegy
              árát és egyéb módon sem biztosít kompenzációt.
            </p>
            <br />
            <p>
              9. Az Előadásra késve érkező Látogató a helyét a megkezdődött
              előadás zavarása nélkül, kizárólag az ültető irányításával vagy
              kíséretében, vagy pedig a szünetben foglalhatja el.
            </p>
            <br />
            <p>
              10. A Színház előadásait minden Látogató a saját felelősségére
              tekintheti meg. A Látogató köteles a Játszóhely helyiségeit
              rendeltetésüknek megfelelően használni, továbbá óvni és az
              előírásoknak megfelelően kezelni az épületben található
              eszközöket, berendezéseket, és magát az épületet.
            </p>
            <br />
            <p>
              11. A Játszóhely területén elhagyott értéktárgyakért a Színház nem
              vállal felelősséget.
            </p>
            <br />
            <p>
              12. Az Előadás alatt a kép- és hangrögzítő eszközök, valamint
              mobiltelefonok használata szigorúan tilos. Amennyiben
              munkatársaink azt tapasztalják, hogy ezen tilalmakat - különösen a
              mobiltelefonok bármilyen célú használatának tilalmára vonatkozó
              szabályt - a Látogató megszegi, jogosultak a Látogatót a nézőtér
              elhagyására kötelezni. Ebben az esetben a Látogató sem a Jegy
              árának visszatérítésére, sem egyéb kompenzációra nem jogosult.
            </p>
            <br />
            <p>
              13. A Játszóhelyekre biztonságra veszélyes, és/vagy a közönséget
              zavaró tárgyat, továbbá a nézőtérre ételt és italt bevinni tilos.
              A Játszóhely területén csak a helyszínen vásárolt étel és ital
              fogyasztható.
            </p>
            <br />
            <p>
              14. Rendkívüli esemény (pl. tűz, bombariadó) esetén a Látogató
              köteles betartani a Színház dolgozóinak utasításait, valamint a
              Játszóhely kiürítési tervében szereplő előírások szerint
              viselkedni. A Színház dolgozóinak intézkedései a Látogatóra nézve
              kötelező érvényűek.
            </p>
            <br />
            <p>
              15. A Színház a műsor- és szereplő változás jogát fenntartja. A
              Színház minden tőle elvárhatót megtesz annak érdekében, hogy az
              Előadás esetleges meghiúsulása esetén a Vevőt/Látogatót
              tájékoztassa és a Jegyek visszaváltását elősegítse. Amennyiben a
              Színház az előadást nem tudja az eredetileg meghirdetett
              időpontban megtartani, de az előadás a későbbiekben pótlásra
              kerül, a jegykezelésre (a jegyek visszaváltása, másik jegyre vagy
              ajándékutalványra való átváltása) az előadás pótidőpontjának
              kihirdetésétől számított 14 napon belül az eredeti Jegy és a
              vásárlást igazoló bizonylat felmutatása mellett van lehetőség. A
              visszaváltás a Jegy teljes árán történik, a Jegy árán felül a
              Színház semmilyen további költség vagy kár megtérítésére nem
              köteles.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
