import React, { Component } from "react";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter as Router } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import Routes from "./navigators/routes";
import rootReducer from "./redux/reducers/index";

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init("617055936092914", { options: options });

ReactPixel.pageView();

const firebaseConfig = {
  apiKey: "AIzaSyDGnm-d-2bSQNxwe1SRuGi9W4IIO-tHDVo",
  authDomain: "enszinhazam-f5243.firebaseapp.com",
  projectId: "enszinhazam-f5243",
  storageBucket: "enszinhazam-f5243.appspot.com",
  messagingSenderId: "731281804744",
  appId: "1:731281804744:web:a3cc642b7dd8de86a484fd",
  measurementId: "G-2GKGJVV8JX",
};

const firebaseApp = initializeApp(firebaseConfig);

const analytics = getAnalytics(firebaseApp);
logEvent(analytics, "Page visited");

const middlewares = [thunk];

const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Routes />
        </Router>
      </Provider>
    );
  }
}

export default App;
